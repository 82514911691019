<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产计划单视图页面
	开始时间：2021-01-14
	开发人员：刘巍骏、姚雨宏、康泽鑫
	最后修改：2021-07-30
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="billingview returnview" id="mes_tableBox">
    <!-- 报价操作框 -->
    <div class="billingview_headoperate">
      <!-- 标题 -->
      <div class="head_operateList">
        <ul class="list">
          <li @click="getListDataByCondition(0)" :class="{'selLi':sel_searchBtn==0}">
            全部
          </li>
          <li @click="getListDataByCondition(1)" :class="{'selLi':sel_searchBtn==1}">
            已审
          </li>
          <li @click="getListDataByCondition(2)" :class="{'selLi':sel_searchBtn==2}">
            未审
          </li>
          <li @click="getListDataByCondition(3)" :class="{'selLi':sel_searchBtn==3}">
            自产
          </li>
          <li @click="getListDataByCondition(4)" :class="{'selLi':sel_searchBtn==4}">
            委外
          </li>
          <li>
            <el-popover placement="bottom-start" width="400" v-model="show_searchBox" trigger="manual">
              <div class="billingview_searchTitle">
                <span>根据以下条件搜索:</span>
              </div>
              <el-form ref="form" :model="offer_searchData" size="mini" label-width="100px">
                <el-form-item label="单号:">
                  <el-input v-model="offer_searchData.billingNumber" size="mini" placeholder="请输入内容" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="源单号:">
                  <el-input v-model="offer_searchData.reBillingNumber" size="mini" placeholder="请输入内容" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="供应商:">
                  <el-input v-model="offer_searchData.supplierName" size="mini" placeholder="请输入内容"
                    @focus="judgeRelatedData(1)" clearable></el-input>
                </el-form-item>
                <el-form-item label="部门名:">
                  <el-input v-model="offer_searchData.departName" size="mini" placeholder="请输入内容"
                    @focus="judgeRelatedData(2)" clearable></el-input>
                </el-form-item>
                <el-form-item label="货品名:">
                  <el-input v-model="offer_searchData.productName" size="mini" placeholder="请输入内容"
                    @focus="judgeRelatedData(3)" clearable></el-input>
                </el-form-item>
                <el-form-item label="起止日期:">
                  <el-col :span="10">
                    <el-date-picker type="date" placeholder="选择日期" v-model="offer_searchData.startTime"
                      style="width: 100%;"></el-date-picker>
                  </el-col>
                  <el-col class="line" :span="3" :push="1"> - </el-col>
                  <el-col :span="10">
                    <el-date-picker type="date" placeholder="选择日期" v-model="offer_searchData.endTime"
                      style="width: 100%;"></el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item label="单据类型:">
                  <el-select v-model="offer_searchData.billsType" placeholder="请选择单据类型" style="width: 100%;" clearable>
                    <el-option value="" key="-1" label="全部"></el-option>
                    <el-option v-for="(item,index) in billsModelData" :key="item.id" :label="item.bills_type"
                      :value="item.id" v-show="item.type_classify == productType">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="单据状态:">
                  <el-select v-model="offer_searchData.billsStatus" placeholder="请选择状态" style="width: 100%;" clearable>
                    <el-option value="" key="-1" label="全部"></el-option>
                    <el-option v-for="elem in billStateList" :label="elem.name" :value="elem.value">{{elem.name}}
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="领料状态:">
                  <el-select v-model="offer_searchData.materialStatus" placeholder="请选择状态" style="width: 100%;"
                    clearable>
                   <el-option value="" key="-1" label="全部"></el-option>
                   <el-option v-for="elem in materialStateGet" :label="elem.name" :value="elem.value">{{elem.name}}
                   </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="入库状态:">
                  <el-select v-model="offer_searchData.depotStatus" placeholder="请选择状态" style="width: 100%;" clearable>
                    <el-option value="" key="-1" label="全部"></el-option>
                    <el-option v-for="elem in depotStateIn" :label="elem.name" :value="elem.value">{{elem.name}}
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="付款状态:">
                  <el-select v-model="offer_searchData.payStatus" placeholder="请选择状态" style="width: 100%;" clearable>
                    <el-option value="" key="-1" label="全部"></el-option>
                    <el-option v-for="elem in costInStateOut" :label="elem.name" :value="elem.value">{{elem.name}}
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="采购状态:">
                  <el-select v-model="offer_searchData.purchaseStatus" placeholder="请选择状态" style="width: 100%;"
                    clearable>
                   <el-option value="" key="-1" label="全部"></el-option>
                   <el-option v-for="elem in purchaseState" :label="elem.name" :value="elem.value">{{elem.name}}
                   </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="备注:">
                  <el-input v-model="offer_searchData.remark" size="mini" placeholder="请输入内容" clearable></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="billingview_searchBtnBox">
                    <el-button @click="getListDataByCondition(5)" type="primary">搜索</el-button>
                    <el-button @click="show_searchBox=false">取消</el-button>
                  </div>
                </el-form-item>
              </el-form>
              <span slot="reference" class="billingview_searchBut" @click="show_searchBox=!show_searchBox">
                查询<i class="el-icon-search"></i>
              </span>
            </el-popover>
          </li>
          <li @click="getListDataByCondition(sel_searchBtn)">
            刷新<i class="el-icon-refresh-right"></i>
          </li>
          <li class="noHover" v-if="filterPower && filterPower.departmentType!=3">
            <userPowerSelect :departmentPowerData="departmentPower" :userPowerType="userPowerType"
              @getSelectData="getSelectUserPowerData"></userPowerSelect>
          </li>
          <li @click="costReplacement()" v-if='companyparamlist.param376.split("@@@").includes(UserInfo.user_id.toString())'>
            成本重置<i class="el-icon-refresh-right"></i>
          </li>
        </ul>
      </div>

      <!-- 操作列表 -->
      <div class="head_operateList">
        <ul class="list">
          <li>
            <el-dropdown trigger="click" placement="bottom" @command="createOperateType">
              <span class="el-dropdown-link">
                新增<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="0">按单生产</el-dropdown-item>
                <el-dropdown-item command="1">人工排产</el-dropdown-item>
                <el-dropdown-item command="2">智能排产(MRP)</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li @click="operateBills(0)">
            <i class="el-icon-check"></i>提交
          </li>
          <li @click="operateBills(1)">
            <i class="el-icon-document-checked"></i>审批
          </li>
          <li @click="operateBills(3)">
            <i class="el-icon-document-checked"></i>反审
          </li>
          <li @click="operateBills(2)">
            <i class="el-icon-document-delete"></i>驳回
          </li>
          <li>
            <i class="el-icon-document-copy"></i>复制
          </li>
          <li>
            <el-dropdown trigger="click" placement="bottom" ref="toProcessPring" @command="printProcessBills">
              <span class="el-dropdown-link" @click="getPrintProcessData">
                按工序打印<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="elem.id" v-for="(elem,index) in printProcessData">{{elem.name}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li>
            <billExport :billsTypeSign="1" :billsModelData="billsModelData" :billsClassify="productType"
              :originBillData="productSelData"></billExport>
          </li>
          <!-- <li>
            <el-dropdown trigger="click" placement="bottom" @command="handleCommand">
              <span class="el-dropdown-link">
                操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="0">采购需求</el-dropdown-item>
                <el-dropdown-item command="1">生产用料</el-dropdown-item>
                <el-dropdown-item command="2">用料登记</el-dropdown-item>
                <el-dropdown-item command="3">质检验收</el-dropdown-item>
                <el-dropdown-item command="4">成品入库</el-dropdown-item>
                <el-dropdown-item command="5">完工</el-dropdown-item>
                <el-dropdown-item command="6">采购物料</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li> -->
        </ul>
      </div>
    </div>

    <!-- 生产产品显示表格 -->
    <div class="billingview_tablewrapper billing_table">
      <el-table ref="productionTableList" :data="productData" @select="getSelectedProdData"
        @select-all="getSelectedAllData" :summary-method="getSummaries" show-summary height="100%" border
        style="width: 100%">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="35">
          <template slot="header" slot-scope="scope">
            <el-tooltip placement="bottom-start" effect="light">
              <div slot="content">
                <ul class="billsList_relvanceTips scollStyle">
                  <li v-for="elem in showListFiledData" v-if="elem.classify == -1 || elem.classify == productType">
                    <el-checkbox v-model="elem.is_show">{{elem.name}}</el-checkbox>
                  </li>
                </ul>
              </div>
              <i class="el-icon-setting"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- 全选 -->
        <el-table-column type="selection" fixed width="30"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" fixed width="50">
          <template slot-scope="scope">
            <div v-if="scope.row.is_submit<4">
              <el-tooltip placement="right" effect="light">
                <div slot="content">
                  <ul class="billsList_relvanceTips scollStyle">
                    <li v-if="scope.row.is_submit<4" @click="updateBillData(scope.row,1)">
                      <i class="el-icon-edit-outline operate_icon" title="修改"></i>
                      <span class="left_padding_10">修改</span>
                    </li>
                    <li @click="updateBillData(scope.row,2)">
                      <i class="el-icon-view operate_icon" title="预览"></i>
                      <span class="left_padding_10">预览</span>
                    </li>
                    <li v-if="[1,3].includes(scope.row.is_submit)" @click="judgeRejectBills(scope.row)">
                      <i class="el-icon-warning-outline operate_icon" title="驳回"></i>
                      <span class="left_padding_10">驳回</span>
                    </li>
                    <li v-if="scope.row.is_submit<4" @click="judgeDeleteBills(scope.row)">
                      <i class="el-icon-delete operate_icon" title="作废"></i>
                      <span class="left_padding_10">作废</span>
                    </li>
                  </ul>
                </div>
                <i class="el-icon-more icon_color_red"></i>
              </el-tooltip>
            </div>
            <div v-if="scope.row.is_submit >= 4">
              <i class="el-icon-view operate_icon" title="预览" @click="updateBillData(scope.row,2)"></i>
            </div>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="bills_no" label="单号" sortable width="150" v-if="showListFiledArr.includes(0)"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="span_pointer span_color_blue" title="点击查看"
              @click="updateBillData(scope.row,2)">{{scope.row.bills_no}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="bills_date" label="单据日期" width="150" v-if="showListFiledArr.includes(1)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="is_submit" label="单据状态" width="80" v-if="showListFiledArr.includes(3)">
          <template slot-scope="scope">
            <!-- 驳回显示 -->
            <div v-if="scope.row.is_submit == 2">
              <rejectBillTips :originBillId="scope.row.id" :originBillState="scope.row.is_submit" :billsSign="1">
              </rejectBillTips>
            </div>

            <!-- 其它显示 -->
            <div v-else>
              <el-tooltip :disabled="scope.row.originBillsMain.length == 0" placement="right" effect="light">
                <div slot="content">
                  <!-- 源单信息 -->
                  <ul class="billsList_relvanceTips" v-if="scope.row.is_submit != 2">
                    <li v-for="elem in scope.row.originBillsMain"
                      @click="getRelvanceDetail(elem,elem.sign,elem.billsClassify)">
                      <span class="billsDate">{{elem.billsDate}}</span>
                      【{{elem.billsNo}}】(
                      <span v-if="elem.isSubmit== 0">未提交</span>
                      <span v-if="elem.isSubmit== 1">审核中</span>
                      <span v-if="elem.isSubmit== 2">已驳回</span>
                      <span v-if="elem.isSubmit== 3">复审中</span>
                      <span v-if="elem.isSubmit== 4">已审核</span>)
                    </li>
                  </ul>
                  <!-- 驳回信息 -->
                  <ul class="billsList_relvanceTips" v-if="scope.row.is_submit == 2">
                    <li></li>
                  </ul>
                </div>
                <el-button v-if="scope.row.is_submit == 0" type="info" size="mini">未提交</el-button>
                <el-button v-if="scope.row.is_submit == 1" type="warning" size="mini">审核中</el-button>
                <el-button v-if="scope.row.is_submit == 2" type="danger" size="mini">已驳回</el-button>
                <el-button v-if="scope.row.is_submit == 3" type="primary" size="mini">复审中</el-button>
                <el-button v-if="scope.row.is_submit == 4" type="success" size="mini">已审核</el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="material_state" label="领料状态" width="80" v-if="showListFiledArr.includes(4)">
          <template slot-scope="scope">
            <relvanceBillTipStrip :originBillId="scope.row.id" :stateKeyName="'materialStateGet'"
              :originBillState="scope.row.material_state" :originBillStateType="'1.1'"
              @getRelvanceDetail="getRelvanceDetail"></relvanceBillTipStrip>
          </template>
        </el-table-column>
        <el-table-column prop="depot_state" label="入库状态" width="80" v-if="showListFiledArr.includes(5)">
          <template slot-scope="scope">
            <relvanceBillTipStrip :originBillId="scope.row.id" :stateKeyName="'depotStateIn'"
              :originBillState="scope.row.depot_state" :originBillStateType="'1.2'"
              @getRelvanceDetail="getRelvanceDetail"></relvanceBillTipStrip>
          </template>
        </el-table-column>
        <el-table-column prop="pay_state" label="付款状态" width="80" v-if="showListFiledArr.includes(6)">
          <template slot-scope="scope">
            <relvanceBillTipStrip :originBillId="scope.row.id" :stateKeyName="'costInStateOut'"
              :originBillState="scope.row.pay_state" :originBillStateType="'1.3'"
              @getRelvanceDetail="getRelvanceDetail"></relvanceBillTipStrip>
          </template>
        </el-table-column>
        <el-table-column prop="purchase_state" label="采购状态" width="80" v-if="showListFiledArr.includes(24)">
          <template slot-scope="scope">
            <el-tooltip :disabled="scope.row.purchase_state == 3" placement="right" effect="light">
              <div slot="content">
                <ul class="billsList_relvanceTips">
                  <!-- 关联单据信息 -->
                  <li v-for="elem in scope.row.relevancePurchaseMain" v-if="scope.row.relevancePurchaseMain.length > 0"
                    @click="getRelvanceDetail(elem,elem.sign,elem.billsClassify)">
                    <span class="billsDate">{{elem.billsDate}}</span>
                    【{{elem.billsNo}}】(
                    <span v-if="elem.isSubmit== 0">未提交</span>
                    <span v-if="elem.isSubmit== 1">审核中</span>
                    <span v-if="elem.isSubmit== 2">已驳回</span>
                    <span v-if="elem.isSubmit== 3">复审中</span>
                    <span v-if="elem.isSubmit== 4">已审核</span>)
                  </li>
                  <!-- 生成单据按钮 -->
                  <li v-if="scope.row.relevancePurchaseMain.length == 0">
                    <span @click="generatePurchaseAll(scope.row)">统一生成</span>
                  </li>
                  <li v-if="scope.row.relevancePurchaseMain.length == 0">
                    <span @click="generatePurchaseBySupplier(scope.row)">按供应商生成</span>
                  </li>
                </ul>
              </div>
              <el-button v-if="scope.row.purchase_state == 0" type="danger" size="mini">未请购</el-button>
              <el-button v-if="scope.row.purchase_state == 1" type="primary" size="mini">请购中</el-button>
              <el-button v-if="scope.row.purchase_state == 2" type="success" size="mini">已请购</el-button>
              <el-button v-if="scope.row.purchase_state == 3" type="success" size="mini">不请购</el-button>
              <el-button v-if="scope.row.purchase_state == 4" type="primary" size="mini">待请购</el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="productInfo.product_code" label="货品编号" width="150" v-if="showListFiledArr.includes(8)"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="productInfo.name" label="货品名称" width="160" v-if="showListFiledArr.includes(9)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="productInfo.specifications" label="规格型号" width="160" v-if="showListFiledArr.includes(10)"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="plan_num" label="计划数量" width="80" v-if="showListFiledArr.includes(13)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="manufacture_num" label="生产数量" width="80" v-if="showListFiledArr.includes(14)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="total_amount" label="单据金额" width="80" v-if="showListFiledArr.includes(15)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="create_user_name" label="制单人" width="100" v-if="showListFiledArr.includes(19)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="create_time" label="制单时间" width="180" v-if="showListFiledArr.includes(20)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="check_user_name" label="审核人" width="100" v-if="showListFiledArr.includes(21)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="check_time" label="审核时间" width="180" v-if="showListFiledArr.includes(22)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="customer_name" label="客户名称" width="240" v-if="showListFiledArr.includes(11)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="supplier_name" label="供应商名称" width="240" v-if="showListFiledArr.includes(12)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="relevance_bills_no" label="源单号" width="160" v-if="showListFiledArr.includes(16)"
          >
          <template slot-scope="scope">
            <el-tooltip :disabled="scope.row.originBillsMain.length == 0" placement="right" effect="light">
              <div slot="content">
                <!-- 源单信息 -->
                <ul class="billsList_relvanceTips" v-if="scope.row.is_submit != 2">
                  <li v-for="elem in scope.row.originBillsMain"
                    @click="getRelvanceDetail(elem,elem.sign,elem.billsClassify)">
                    <span class="billsDate">{{elem.billsDate}}</span>
                    【{{elem.billsNo}}】(
                    <span v-if="elem.isSubmit== 0">未提交</span>
                    <span v-if="elem.isSubmit== 1">审核中</span>
                    <span v-if="elem.isSubmit== 2">已驳回</span>
                    <span v-if="elem.isSubmit== 3">复审中</span>
                    <span v-if="elem.isSubmit== 4">已审核</span>)
                  </li>
                </ul>
              </div>
              <span class="span_pointer origin_content" v-if="scope.row.originBillsMain.length > 0"
                @click="getRelvanceDetail(scope.row.originBillsMain[0],scope.row.originBillsMain[0].sign,scope.row.originBillsMain[0].billsClassify)">
                {{scope.row.relevance_bills_no}}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="relevance_number" label="源单数量" width="80" v-if="showListFiledArr.includes(17)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="complete_data_plan" label="计划完工日期" width="120" v-if="showListFiledArr.includes(18)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="complete_date" label="完工时间" width="120" v-if="showListFiledArr.includes(23)"
          show-overflow-tooltip></el-table-column>

        <!-- 操作 -->
        <el-table-column label="操作" fixed="right" width="50">
          <template slot-scope="scope">
            <el-dropdown trigger="click" placement="bottom">
              <span class="el-dropdown-link">
                <i class="el-icon-chat-dot-round el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-edit">修改</el-dropdown-item>
                <el-dropdown-item icon="el-icon-delete">删除</el-dropdown-item>
                <el-dropdown-item icon="el-icon-refresh-left">重新生产</el-dropdown-item>
                <el-dropdown-item icon="el-icon-view">
                  <span @click="toMesDetail(scope.row)">查看详情</span>
                </el-dropdown-item>
                <el-dropdown-item icon="el-icon-view">查看任务</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 表格分页 -->
    <div class="billing_tablePage">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="pageData.currentPage" :page-sizes="[10, 20, 30, 40,50]" :page-size.sync="pageData.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="pageData.totalPage">
      </el-pagination>
    </div>

    <!-- 弹框 -->
    <!-- 驳回条件弹框 -->
    <el-dialog title="驳回原因" :visible.sync="show_reject" width="500px" append-to-body>
      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="rejectData">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_reject = false">取 消</el-button>
        <el-button size="small" type="primary" @click="rejectBills">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 反审条件弹框 -->
    <el-dialog title="反审原因" :visible.sync="show_again" width="500px" append-to-body>
      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="againstData">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_again = false">取 消</el-button>
        <el-button size="small" type="primary" @click="againstBillsData">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 作废条件弹框 -->
    <el-dialog title="作废原因" :visible.sync="show_delete" width="500px" append-to-body>
      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="deleteData">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_delete = false">取 消</el-button>
        <el-button size="small" type="primary" @click="deleteBillsData">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 工时信息弹框(下一节点工时信息为员工填写时用到) -->
    <el-dialog title="预计工时" :visible.sync="show_hourseBox" width="400px" :before-close="closeHourseBox" append-to-body>
      <!-- 工时信息弹框 -->
      <div class="billing_dialog_form">
        <ul class="single_ul">
          <li>
            <div class="title">下节点预计工时:</div>
            <div class="mulData">
              <el-input size="small" class="mulInput" type="number" v-model.number="nextHourse" placeholder="请输入工时时长">
              </el-input>
              <span class="mulSpan" v-if="nextNodeBaseInfo.working_hours_type == 1">天</span>
              <span class="mulSpan" v-if="nextNodeBaseInfo.working_hours_type == 2">小时</span>
            </div>
          </li>
        </ul>
      </div>

      <!-- 提交按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="commitNodeData(nextNodeBaseInfo,nextHourse)">确认</el-button>
        <el-button size="small" @click="closeHourseBox">取消</el-button>
      </span>
    </el-dialog>

    <!-- 订单生成生产单后的提示弹框(样式在公共样式中) -->
    <el-dialog title="生成成功" class="padding_top_10" :visible.sync="show_generateMes" width="400px" append-to-body>
      <div class="generageAll">
        <!-- 生成成功提示弹框 -->
        <div class="generageTitle">订单转换生产单完成</div>
        <!-- 总计 -->
        <div class="generateTotal">
          <span class="totalItem">处理总数:{{generateResData.allNum}}</span>
          <span class="totalItem">
            成功数:
            <span class="color_success">{{generateResData.successNum}}</span>
          </span>
          <span class="totalItem">
            失败数:
            <span class="color_danger">{{generateResData.errorNum}}</span>
          </span>
        </div>
        <!-- 生成详情 -->
        <div class="generateDetail">
          <!-- 成功详情 -->
          <div class="detailSuccess">
            <span class="successTitle">成功详情:</span>
            <ul class="detailItem">
              <li>
                <span class="itemTitle">新生成数:</span>
                {{generateResData.newGenerateNum}}
              </li>
              <li>
                <span class="itemTitle">重复生成数:</span>
                {{generateResData.repetitionNum}}
              </li>
            </ul>
          </div>
          <!-- 失败详情 -->
          <div class="detailError">
            <span class="errorTitle">失败详情:</span>
            <ul class="detailItem">
              <li>
                <span class="itemTitle">未设bom数:</span>
                {{generateResData.noSetBomNum}}
              </li>
              <li>
                <span class="itemTitle">已生成数:</span>
                {{generateResData.isGenerateNum}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 确认按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="successGenerateMesByOrder">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增生产单据组件 -->
    <nodeBills :nodeTypeCode="nodeTypeCode" :billsClassify="billsClassify" :billsTypeSign="billsTypeSign"
      :updateDefaultData="updateDefaultData" :operateType="operateType" :generateBillsId="generateBillsId"
      :generateBillsSign="generateBillsSign" :generateBillsOriginData="generateBillsOriginData"
      :billsTypeDefaultCode="billsTypeDefaultCode" :isGenerateOperateType="isGenerateOperateType"
      :dictionariesDefaultData="dictionariesDefaultData" v-if="show_nodeBills"></nodeBills>

    <!-- 部门选择弹框 -->
    <departmentTree @SelectedData="getDepartmentData" v-if="show_departmentBox"></departmentTree>

    <!-- 供应商选择弹框 -->
    <supplierList @SelectedData="getSupplierData" v-if="show_supplierBox"></supplierList>

    <!-- 产品选择弹框 -->
    <productList @SelectedData="getProductData" v-if="show_productBox" :bomCondition="1" :isMultiple="0"></productList>

    <!-- 单据列表弹框 -->
    <billingList @SelectedData="getBillingData" :conditionObj="conditionObj" :showSignArr="showSignArr" :saleSelType="1"
      v-if="show_billsComponent"></billingList>

    <!-- 生产mrp -->
    <productionPlanMrp v-if="showMesMrpBox"></productionPlanMrp>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入组件
  import nodeBills from '@/components/commComponent/billing/nodeBills'; //新建单据公共组件
  import departmentTree from '@/components/commComponent/tree/tree_department'; //部门选择组件
  import supplierList from '@/components/commComponent/list/list_supplier'; //供应商列表组件
  import productList from '@/components/commComponent/list/list_product'; //产品列表组件
  import userPowerSelect from '@/components/commComponent/power/user_power_select'; //用户权限查询组件
  import billExport from '@/components/commComponent/dialog/bill_export'; //单据打印组件
  import billingList from '@/components/commComponent/list/list_billing'; //单据列表组件
  import relvanceBillTipStrip from '@/components/commComponent/tooltip/relvanceBillTipStrip.vue'; //单据关联其它单据信息
  import rejectBillTips from '@/components/commComponent/tooltip/rejectBillTips'; //单据驳回信息显示组件
  import productionPlanMrp from './productionplan_mrp.vue'; //生产mrp组件

 

  // 导入接口方法
  import workApi from '@/network/system/workflowSetup.js'; //工作流程相关接口请求
  import api from '@/network/production/production.js'; //生产模块相关接口
  import apiDepart from '@/network/hr/organization.js'; //人力模块
  import purchaseApi from '@/network/stock/purchase.js'; //采购模块接口

  export default {
    name: 'productionView',
    data() {
      return {
        //控制开关
        show_nodeBills: false, //控制显示公共单据组件弹框
        show_searchBox: false, //是否显示搜索
        show_departmentBox: false, //是否显示部门组件弹框
        show_supplierBox: false, //是否显示供应商组件弹框
        show_productBox: false, //是否显示货品弹框
        show_billsComponent: false, //是否显示单据选择弹框
        showMesMrpBox: false, //是否显示生产mrp功能弹框

        //表格数据
        productData: [], //传入表格组件的数据
        productStateList: [], //状态数据
        CurrSelData: {}, //当前操作数据
        productSelData: [], //选中数据
        generateResData: {}, //订单生成生产单结果集
        rejectData: '', //驳回条件
        deleteData: '', //作废条件
        againstData: '', //反审条件
        showListFiledData: [], //列表可显示的字段
        showListFiledArr: [], //列表需要显示的

        //节点相关
        show_hourseBox: false, //控制显示工时信息弹框
        nextHourse: 2, //下一节点预计工时
        nextNodeBaseInfo: {}, //下一节点基本信息

        //分页数据
        pageData: {
          currentPage: 1, //当前页
          totalPage: 0, //总页数
          pageSize: 20 //页面大小
        },

        //控制开关
        show_searchBox: false, //是否显示搜索
        sel_searchBtn: 0, //当前选中查询条件(0:全部 1:已审 2:未审 3:状态 4:高级查询)
        show_reject: false, //控制驳回弹框是否显示
        show_delete: false, //控制作废弹框是否显示
        show_again: false, //控制反审弹框是否显示
        show_generateMes: false, //控制订单生成生产单后的弹框

        //传入子组件的数据
        nodeTypeCode: 'SYS-NODE-TYPE-003', //节点分类code
        billsTypeSign: 1, //单据分类标签(1:表示生产)
        generateBillsId: -1, //需要生成其他单据的id
        generateBillsSign: -1, //源单分类标识
        generateBillsOriginData: {}, //需要生成其他单据的数据源
        billsClassify: -1, //单据分类
        operateType: 0, //操作类型(0:新增 1:修改  2:查看)
        billsTypeDefaultCode: '', //默认单据类型
        isGenerateOperateType: -1, //是否为生成类型操作
        updateDefaultData: { //修改时默认流程和节点等信息
          nodeId: -1, //节点id
          processId: -1, //流程id
        },
        dictionariesDefaultData: {}, //数据字典默认数据
        //用户查询权限子组件
        userPowerType: 1, //默认全公司

        // 高级搜索框数据
        offer_searchData: { //高级搜索数据
          billingNumber: '', //单号
          reBillingNumber: '', //源单号
          supplierName: '', //供应商
          deptId: '', //部门id
          supplierId: '', //供应商id
          departName: '', //部门名
          productName: '', //货品名称
          productId: '', //货品id
          billsType: '', //单据类型id
          materialStatus: '', //领料状态
          depotStatus: '', //入库状态
          payStatus: '', //付款状态
          purchaseStatus: '', //采购状态
          startTime: '', //开始时间
          endTime: '', //结束时间
          billsStatus: '', //单据状态
          collectStatus: '', //收款状态
          invoiceStatus: '', //发票状态
          outStatus: '', //出库状态
          remark: '' //备注
        },
        billsModelData: [], //当前模块单据类型数据
        personnel: -1, //员工
        departmentPower: [], //权限部门
        filterPower: {}, //符合条件的权限对象

        //单据选择子组件相关
        conditionObj: {}, //单据查询条件
        showSignArr: [], //可以显示的单据模块

        //按工序打印相关
        printProcessData: [], //当前选择的生产单所含工序数据
      }
    },
    created() {},
    mounted() {
      // 初始化界面所需参数(vuex数据)
      this.initVuextData();
      //初始化列表显示字段数据
      this.initListShowFiledData();
    },
    updated() {
      this.$nextTick(() => {
        //表格加载处理
        this.$refs['productionTableList'].doLayout();
      });
    },
    computed: {
      ...mapState({
        bills_operate_btn: state => state.commComponent.bills_operate_btn, //新建单据弹框操作按钮
        userPowerInfo: state => state.user.userPowerInfo, //权限
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        billTemplateData: state => state.system.billTemplateData, //所有单据类型数据
        ManufactureType: state => state.system.ManufactureType, //生产类型
        firstLevelPersonnelData: state => state.hr.firstLevelPersonnelData, //一级部门数据
        companyparamlist: state => state.companyparamlist, //获取企业级参数
        mesListFileData: state => state.bill_list_config.mesListFileData, //生产计划列表可显示字段
        billStateList: state => state.bill_state.billStateList, //单据状态
        materialStateGet: state => state.bill_state.materialStateGet, //领料状态
        depotStateIn: state => state.bill_state.depotStateIn, //入库状态
        costInStateOut: state => state.bill_state.costInStateOut, //付款状态
        purchaseState: state => state.bill_state.purchaseState, //采购状态
      }),
      //路由传入的参数(生产单据类型(0:生产计划单))
      productType() {
        //路由获取的参数需要转换为int类型
        return Number(this.$route.query.productType);
      },
      //路由传入的默认单据类型code
      productBillCode() {
        return this.$route.query.productBillCode;
      },
    },
    watch: {
      //监听新建弹框关闭或显示
      show_nodeBills(newVal) {
        if (!newVal && this.bills_operate_btn != 4) {
          this.getListDataByCondition(this.sel_searchBtn);
        }
      },
      // 监听显示列表数据发生变化
      showListFiledData: {
        handler(newVal) {
          this.getCurShowFiledArr();
        },
        deep: true
      },
    },
    methods: {
      ...mapMutations([
        'SHOW_SUPPLIERBOX', //控制供应商弹框是否显示
        'SHOW_DEPARTMENTBOX', //控制部门弹框是否显示
        'SHOW_PRODUCTBOX', //控制货品是否显示
        'SHOW_BILLINGBOX', //控制单据选择弹框是否显示
      ]),
      ...mapActions([
        'getUserPowerInfo', //获取用户操作权限
        'getAllUserData', //请求企业用户数据
        'getAllManufactureType', //生产类型
        'getelectronicTemplate', //获取所有单据类型
      ]),
      ...mapMutations({}),

      /* 初始化界面所需数据vuex数据 */
      async initVuextData() {
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#mes_tableBox", 4, '列表初始化中,请稍后...');
        //判断用户权限
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
        }
        //判断生产类型是否存在
        if (this.ManufactureType.length == 0) { //不存在则请求数据
          await this.getAllManufactureType();
        }
        //获取单据类型数据
        this.getBillTemplateData();
        //获取用户查询权限权限范围
        await this.getUserPower()
        this.loading.close();
        //获取全部列表数据
        this.getListDataByCondition(0);
      },

      /* 获取单据类型 */
      async getBillTemplateData() {
        //判断单据模板数据是否存在
        if (this.billTemplateData.length == 0) {
          await this.getelectronicTemplate();
        }
        if (this.billTemplateData.length != 0) {
          //根据分类获取单据分类信息
          this.billsModelData = this.commonJsExtend.getBillsClassifyDataByType(2, this.nodeTypeCode, this
            .billTemplateData).children;
          //根据code设置默认值
          if (!!this.productBillCode) {
            //判断是否为订单类型(查询所有订单)
            if (this.productBillCode == 'DOC-M-001') { //生产计划单查询所有单据类型
              this.offer_searchData.billsType = '';
            } else {
              this.offer_searchData.billsType = this.commonJsExtend.getBillsDataByType(2, this.productBillCode, this
                .billTemplateData).id
            }
          }
        }
      },

      /* 获取用户权限 */
      async getUserPower() {
        //判断是否有用户权限数据
        if (this.userPowerInfo) {
          this.filterPower = this.userPowerInfo.filter(item => item.parentLevel == 16 && item.childrenLevel == 1)[
            0] //获取符合条件的权限对象
          if (this.filterPower) {
            let departments = []
            let data = {}
            //获取权限部门类型
            this.userPowerType = parseInt(this.filterPower.departmentType);
            //判断权限部门
            if (this.filterPower.departmentType == 0) { //本部门
              departments.push(this.UserInfo.dept_id); //传入当前登录用户部门id
              data.deptId = departments.join(',');
              data.isAdmin = 0
            } else if (this.filterPower.departmentType == 1) { //全公司
              data.deptId = '';
              data.isAdmin = 1;
            } else if (this.filterPower.departmentType == 2) { //指定部门
              departments.push(this.filterPower.departmentId) //传入部门id
              data.deptId = departments.join(',');
              data.isAdmin = 0;
            } else if (this.filterPower.departmentType == 3) { //仅个人
            }
            await apiDepart.findFirstLevelDeptUser(data).then(res => {
              if (res.code == 200) {
                this.departmentPower = res.data.departmentList; //赋值并倒叙
              }
            })
          }
        }
      },

      /* 根据条件查询列表信息type(0:全部 1:已审 2:未审 3:自产 4:委外 5:高级查询)*/
      getListDataByCondition(type) {
        //判断是否有查询权限
        if (this.commonJsExtend.isHaveThePower(16, 1, 1, '查询')) {
          return
        }
        //设置查询按钮选中
        this.sel_searchBtn = type;
        //定义传入后端的参数
        let data = {
          pageIndex: this.pageData.currentPage, //当前页
          pageSize: this.pageData.pageSize, //页面大小
          delete_flag: 0, //删除标识(0:未作废  1:已作废)
        }
        //定义高级查询条件
        let {
          billingNumber, //单号
          reBillingNumber, //源单号
          supplierName, //供应商
          departName, //部门名
          deptId, //部门id
          supplierId, //供应商id
          productName, //货品名
          productId, //货品id
          billsType, //单据类型
          materialStatus, //领料状态
          depotStatus, //入库状态
          payStatus, //付款状态
          purchaseStatus, //采购状态
          startTime, //开始时间
          endTime, //结束时间
          billsStatus, //单据状态
          remark //备注
        } = this.offer_searchData

        //判断查询类型
        if (type == 0) { //全部
        } else if (type == 1) { //已审
          data.isSubmit = 4
        } else if (type == 2) { //未审
          data.isSubmit = 1
        } else if (type == 3) { //自产
          let id = this.commonJsExtend.getDictMatchingDataByCode('DICT-SMM-001', this.ManufactureType).id
          data.manufacture_type = id
        } else if (type == 4) { //委外
          let id = this.commonJsExtend.getDictMatchingDataByCode('DICT-SMM-002', this.ManufactureType).id
          data.manufacture_type = id
        } else if (type == 5) { //高级条件查询
          let today = new Date()
          today.setHours(0, 0, 0, 0)
          if ((startTime != '' && startTime != null) || (endTime != '' && endTime != null)) { //起止时间其中一个不为空
            if (startTime == '' || endTime == '') { //起止时间其中一个为空
              this.$message({
                type: 'warning',
                message: '查询条件为起止时间时必须两个都填写!',
                duration: this.elDuration
              })
              return
            } else if (endTime.getTime() - startTime.getTime() < 0) { //结束日期不能早于开始日期
              this.$message({
                type: 'warning',
                message: '结束日期不能早于开始日期!',
                duration: this.elDuration
              })
              return
            } else if (today.getTime() < startTime.getTime() || today.getTime() < endTime.getTime()) { //所选择的日期不能早于当前日期
              this.$message({
                type: 'warning',
                message: '所选择的日期不能早于当前日期!',
                duration: this.elDuration
              })
              return
            } else { //限制日期选择区间
              let duringTime = (endTime.getTime() - startTime.getTime()) / (24 * 60 * 60 * 1000)
              if (duringTime > 90) {
                this.$message({
                  type: 'warning',
                  message: '日期区间不能超过90天!',
                  duration: this.elDuration
                })
                return
              }
            }
          }
          this.show_searchBox = false
        }
        //判断当前员工查询权限
        if (this.userPowerType == 3) { //仅个人
          data.createUserId = this.UserInfo.user_id;
        } else { //全公司,本部门,指定部门
          //判断是否查询全部
          if (this.personnel == -1) { //查询全部
            let userArr = [];
            this.departmentPower.forEach((itemI, indexI) => {
              if (itemI.userList) {
                itemI.userList.forEach((itemJ, indexJ) => {
                  userArr.push(itemJ.user_id);
                })
              }
            })
            data.createUserId = userArr.join(",");
          } else {
            data.createUserId = this.personnel.join(",");
          }
        }

        // 单据类型
        if (billsType != '') {
          data.billingTypeId = billsType;
        }
        //设置查询条件(单按钮查询不需要带高级查询条件)
        if (![0, 1, 2, 3, 4].includes(type)) {
          //单号
          if (billingNumber != '') {
            data.billing_no = billingNumber;
          }
          //源单号
          if (reBillingNumber != '') {
            data.relevance_bills_no = reBillingNumber;
          }
          //供应商
          if (supplierName != '') {
            data.supplier_id = supplierId;
          }
          //部门名
          if (departName != '') {
            data.dept_id = deptId;
          }
          //货品名
          if (productName != '') {
            data.product_info_id = productId;
          }
          //开始时间
          if (startTime != '') {
            data.startTime = this.commonJsExtend.getDateTime(startTime, 0);
          }
          //结束时间
          if (endTime != '') {
            data.endTime = this.commonJsExtend.getDateTime(endTime, 0);
          }
          //单据状态
          if (!!billsStatus.toString()) {
            data.isSubmit = billsStatus;
          }
          //领料状态
          if (!!materialStatus.toString()) {
            data.material_state = materialStatus;
          }
          //入库状态
          if (!!depotStatus.toString()) {
            data.depot_state = depotStatus;
          }
          //付款状态
          if (!!payStatus.toString()) {
            data.pay_state = payStatus;
          }
          //采购状态
          if (!!purchaseStatus.toString()) {
            data.purchase_state = purchaseStatus;
          }
          //备注
          if (remark != '') {
            data.remark = remark;
          }
        }

        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#mes_tableBox", 4, '数据更新中,请稍后...');
        // 发送请求
        api.findMesMainByCondition(data).then(res => {
          //关闭弹框
          this.loading.close();
          //验证请求后的结果
          if (res.code == 200) {
            //将数据查询到的数据存入data

            this.productData = res.data.rows;
            //获取分页数据
            this.pageData.totalPage = res.data.total
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /*成本重置计算*/
      costReplacement(){
        console.log(this.productSelData)
        let  id=[]
        this.productSelData.forEach((item,index)=>{
          id.push(item.id)
        })
        let data={
          mesIds:id.join(",")
        }
        console.log(data)
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#mes_tableBox", 4, '成本重置中,请稍后...');
        api.costReplacement(data).then(res=>{
          //验证请求后的结果
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: res.message
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
          //关闭弹框
          this.loading.close();
        })

      },

      /* 获取用户权限组件选择出来的数据 */
      getSelectUserPowerData(data) {
        // 判断是否为全部查询
        if (data[0] == -1) { //全部查询
          this.personnel = -1;
        } else { //非全部查询
          this.personnel = data;
        }
        //调用查询方法
        this.getListDataByCondition(0);
      },

      /* 审核单据type(0:提交 1:审批  2:驳回) */
      operateBills(type, command) {
        // 判断是否可以操作
        if (this.productSelData.length == 0) { //判断为空
          this.$message({
            type: 'warning',
            message: '请选择需要操作的数据!',
            duration: this.elDuration
          })
          return
        } else if (this.productSelData.length > 1) { //大于1的情况
          this.$message({
            type: 'warning',
            message: '一次只能操作一条数据!',
            duration: this.elDuration
          })
          return
        }
        //将当前选择数据存入data
        this.CurrSelData = this.productSelData[0];
        // 判断哪种操作
        if (type == 0) { //提交
          //定义可提交的状态
          let deleteState = [0, 2] //0:未提交  2:已驳回
          // 判断该数据是否可以提交
          if (!deleteState.includes(this.productSelData[0].is_submit)) {
            this.$message({
              type: 'warning',
              message: '当前状态不可提交',
              duration: this.elDuration
            })
            return
          }
          //定义传入后端的值
          let submitData = {
            isSubmit: 1, //状态
            isFinish: 2, //是否完成
            workingType: 0, //工时类型
            billingName: this.productSelData[0].billingTypeInfo.bills_type,
            relevanceId: this.productSelData[0].id, //生产模块单据id
            processId: this.productSelData[0].process_id, //流程id
            statusId: this.productSelData[0].status_id, //节点id
          }
          // 请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#mes_tableBox", 4, '提交审核中,请稍后...');
          // 发送提交审核请求
          api.updateMesMainStatusIsSubmit(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.getListDataByCondition(this.sel_searchBtn);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (type == 1) { //审批(通过)
          //判断是否有审核权限
          if (this.commonJsExtend.isHaveThePower(16, 1, 7, '审核')) {
            return
          }
          // 定义可审核状态
          let deleteState = [1, 3] //1:审核中  3:复审中
          // 判断该数据是否可以提交
          if (!deleteState.includes(this.productSelData[0].is_submit)) {
            this.$message({
              type: 'warning',
              message: '当前状态不可审批',
              duration: this.elDuration
            })
            return
          }
          // 审核确认提示框
          this.$confirm('您确认审核该单据吗,审核后的单据无法修改和作废?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let processStatusId = []
            if (this.productSelData[0].processStatusId) {
              processStatusId = this.productSelData[0].processStatusId.split(',') //节点id转数组
            }
            let statusId = this.productSelData[0].status_id //当前节点id
            let statusIdIndex = processStatusId.indexOf(statusId + '') //当前节点id在数组中的索引
            let nextNodeId = processStatusId[statusIdIndex + 1] //下一节点id
            if (nextNodeId != processStatusId.pop()) { //当下一节点不为最后一个时
              let nodeNextData = {
                id: nextNodeId - ''
              }
              // 发送请求获取下一节点信息(不需要加载loading框)
              workApi.getWorkflowSetupNodeById(nodeNextData).then(res => {
                if (res.code == 200) {
                  //获取节点基本信息
                  if (res.data.node.working_type == 0) { //当工时类型为人工填写时
                    this.nextNodeBaseInfo = res.data.node;
                    this.show_hourseBox = true //显示工时弹框
                  } else {
                    this.show_hourseBox = false //关闭工时弹框
                    this.commitNodeData(res.data.node) //发送审核请求
                  }
                } else {
                  this.$message({
                    type: 'error',
                    message: '节点信息获取失败,请确认...',
                    duration: this.elDuration
                  })
                }
              })
            } else { //当下一节点为最后一个时
              this.commitNodeData({
                working_hours: 0,
                working_hours_type: 0,
                working_type: 0
              }) //发送审核请求
            }
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消审核',
              duration: this.elDuration
            });
          });
        } else if (type == 2) { //驳回
          //定义可驳回状态
          let rejectState = [1, 3]; //1:审核中  3:复审中
          // 判断是否满足驳回条件
          if (!rejectState.includes(this.productSelData[0].is_submit)) {
            this.$message({
              type: 'warning',
              message: '当前状态不可驳回',
              duration: this.elDuration
            })
            return
          }
          //显示驳回弹框
          this.show_reject = true;
          //清空驳回条件
          this.rejectData = "";
        }
        else if(type==3){
          //定义可反审的状态
          let againstState=[4];//已审核
          // 判断是否满足反审条件
          if (!againstState.includes(this.productSelData[0].is_submit)) {
            this.$message({
              type: 'warning',
              message: '当前状态不可反审',
              duration: this.elDuration
            })
            return
          }
          //显示反审弹框
          this.show_again = true;
          //清空反审条件
          this.againstData = "";
        }
      },

      /* 新增操作方式 */
      createOperateType(command) {
        //判断方式
        if (command == 0) { //按单生成
          this.exportBySellOrder();
        } else if (command == 1) { //生产库存
          this.addBillsData();
        } else if (command == 2) { //MRP(需求计划)
          this.showMesMrpBox = true;
        }
      },

      /* 关闭生产mrp功能弹框 */
      closeMesMrpBox() {
        this.showMesMrpBox = false;
      },

      /* 获取当前选择的生产计划单的工序 */
      getPrintProcessData() {
        //判断是否选择单据
        if (this.productSelData.length == 0) {
          this.$message({
            type: 'warning',
            message: '请先选择单据',
            duration: this.elDuration
          })
          this.$refs.toProcessPring.hide();
          return
        }

        //判断是否需要获取数据
        if (this.printProcessData.length > 0) {
          return
        }

        //获取数据
        let data = {
          mesMainId: this.productSelData[0].id,
        }
        //加载loadign框
        this.loading = this.commonJsExtend.customLoading("#mes_tableBox", 4, '单据工序信息获取中,请稍候...');
        //发送请求
        api.findMesProcessByMesMainIdToPrint(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取工序数据
            this.printProcessData = res.data;
            //判断是否有工序数据
            if (this.printProcessData.length == 0) {
              this.$message({
                type: 'warning',
                message: "该单据未设置工序,请确认!",
                duration: this.elDuration
              })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 按工序打印工序单 */
      printProcessBills(command) {
        //设置打印模板id
        setCookie('printMouldId', 18);
        //设置打印模板分类
        setCookie('printMouldClassify', 8);
        //设置单据id
        setCookie('billsId', command);
        //设置标识为生产
        setCookie('billsTypeSign', 1);
        //设置表头类型为工序单
        setCookie('mesThName', 6);
        //设置是否显示旧物料编码
        setCookie('showOldProductCode', this.companyparamlist.param345 == "0" ? true : false);
        //打开打印页面
        window.open(baseUrl + '/upload/orderPrint/PrintModel/html/mes/mes_single_implement_model_hws.html');
      },

      /* 操作菜单 */
      handleCommand(command) {},

      /* 订单生成生产单后查看结果 */
      successGenerateMesByOrder() {
        //关闭提示框
        this.show_generateMes = false;
        //查询新的列表数据
        this.getListDataByCondition(this.sel_searchBtn)
      },

      /* 审核请求 */
      commitNodeData(dataInfo, nextHourse) {
        //获取工时相关信息
        let {
          working_hours,
          working_hours_type,
          working_type,
        } = dataInfo
        //当员工填写时
        if (nextHourse) {
          working_hours = nextHourse //工时为输入框的值
        }
        //定义传入后端的值
        let submitData = {
          //基本数据
          billingName: this.productSelData[0].billingTypeInfo.bills_type, //	单据类型名称
          relevanceId: this.productSelData[0].id, //单据id
          statusId: this.productSelData[0].status_id, //节点id
          processId: this.productSelData[0].process_id, //流程id
          //通过信息
          workingHours: working_hours, //工时时长
          workingHoursType: working_hours_type, //工时单位
          workingType: working_type, //工时类型
          isFinish: 0, //是否完成
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#mes_tableBox", 4, '审核通过中,请稍候...');
        // 发送通过审核请求
        api.updateMesMainStatusYes(submitData).then(res => {
          this.loading.close();
          this.show_hourseBox = false; //关闭工时弹框
          if (res.code == 200) {
            this.getListDataByCondition(this.sel_searchBtn);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: 3000
            })
          }
        })
      },

      /* 判断是否驳回单据 */
      judgeRejectBills(data) {
        //定义可驳回状态
        let rejectState = [1, 3]; //1:审核中  3:复审中
        // 判断是否满足驳回条件
        if (!rejectState.includes(data.is_submit)) {
          this.$message({
            type: 'warning',
            message: '当前状态不可驳回',
            duration: this.elDuration
          })
          return
        }
        this.$confirm("您确认驳回该数据吗?!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //将当前驳回数据存入data
          this.CurrSelData = data;
          //显示驳回原因弹框
          this.show_reject = true;
          //清空驳回原因
          this.rejectData = "";
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消驳回',
            duration: this.elDuration
          });
        })
      },

      /* 驳回单据 */
      rejectBills() {
        // 判断驳回原因是否已填
        if (this.rejectData.trim() == "") {
          this.$message({
            type: 'warning',
            message: '请填写驳回原因!',
            duration: this.elDuration
          })
          return
        }
        //关闭弹框
        this.show_reject = false;
        //定义传入后端的值
        let submitData = {
          //基本数据
          billingName: this.CurrSelData.billingTypeInfo.bills_type, //单据类型名称
          relevanceId: this.CurrSelData.id, //单据id
          statusId: this.CurrSelData.status_id, //节点id
          processId: this.CurrSelData.processId, //流程id
          isSubmit: 2, //订单状态
          isFinish: 2, //是否完成
          workingType: 0, //工时类型
          //驳回信息
          rejectReason: this.rejectData, //驳回原因
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#mes_tableBox", 4, '单据驳回中,请稍后...');
        // 发送驳回请求
        api.updateMesMainStatusNo(submitData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '单据驳回成功!',
              duration: this.elDuration
            })
            this.getListDataByCondition(this.sel_searchBtn);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },


      /*反审单据*/
      againstBillsData(){
        // 判断反审原因是否已填
        if (this.againstData.trim() == "") {
          this.$message({
            type: 'warning',
            message: '请填写反审原因!',
            duration: this.elDuration
          })
          return
        }
        //关闭弹框
        this.show_again = false;
        //定义传入后端的数据
        let submitData = {
          mesId: this.CurrSelData.id,
          reason: this.againstData,
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#mes_tableBox", 4, '单据反审中,请稍后...');
        // 发送反审请求
        api.againstMesBill(submitData).then(res=>{
          this.loading.close();
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '单据反审成功!',
              duration: this.elDuration
            })
            this.getListDataByCondition(this.sel_searchBtn);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
        console.log(this.CurrSelData)
        console.log(666)
      },
      /* 判断是否作废单据 */
      judgeDeleteBills(data) {
        //判断是否有作废权限
        if (this.commonJsExtend.isHaveThePower(16, 1, 3, '作废')) {
          return
        }
        this.$confirm("您确认作废该数据吗?!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //将当前作废数据存入data
          this.CurrSelData = data;
          //显示作废原因弹框
          this.show_delete = true;
          //清空作废原因
          this.deleteData = "";
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消作废',
            duration: this.elDuration
          });
        })
      },

      /* 作废单据 */
      deleteBillsData() {
        // 判断作废原因是否已填
        if (this.deleteData.trim() == "") {
          this.$message({
            type: 'warning',
            message: '请填写作废原因!',
            duration: this.elDuration
          })
          return
        }
        //关闭弹框
        this.show_delete = false;
        //定义传入后端的数据
        let deleteData = {
          mesMainId: this.CurrSelData.id, //生产单id
          mesMainName: this.CurrSelData.bills_no,
          reason: this.deleteData, //作废原因
          statusId: this.CurrSelData.status_id, //节点id
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#mes_tableBox", 4, '单据作废中,请稍后...');
        // 发送请求
        api.modifyMesMainDisable(deleteData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '单据作废成功!',
              duration: this.elDuration
            })
            this.getListDataByCondition(this.sel_searchBtn);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            });
          }
        })
      },

      /* 进入生产详情 */
      toMesDetail(data) {
        this.$router.push({
          path: '/productionplan_details',
          query: {
            mesId: data.id,
          }
        })
      },

      /* 生成采购申请单(按供应商生成) */
      generatePurchaseBySupplier(data) {
        let addData = {
          mesId: data.id
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#mes_tableBox", 4, '单据生成中,请稍后...');
        purchaseApi.addPurchaseOrderMainByMes(addData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.$confirm('生产物料转换采购申请单完成,是否跳转采购申请单界面?', '提示', {
              confirmButtonText: '跳转',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$router.push({
                path: 'purchase_view?purchaseType=0&purchaseBillCode=DOC-P-001'
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消跳转!',
                duration: this.elDuration
              });
            });
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 生成采购申请单(统一生成) */
      generatePurchaseAll(data) {
        //判断是否有(采购申请单)新增权限
        if (this.commonJsExtend.isHaveThePower(23, 1, 2, '新增')) {
          return
        }
        //将操作设置为新增
        this.operateType = 0;
        //获取需要回显的单据id(新增为-1)
        this.generateBillsId = data.id;
        //设置源单分类标识(生产)
        this.generateBillsSign = 1;
        //获取需要生成其他单据的数据源
        this.generateBillsOriginData = data;
        //设置单据类型标签(设置为采购模块)
        this.billsTypeSign = 3;
        //设置单据分类类型(采购申请单)
        this.billsClassify = 0;
        //设置单据分类code为采购
        this.nodeTypeCode = 'SYS-NODE-TYPE-002';
        //设置默认单据类型(生产采购申请单)
        this.billsTypeDefaultCode = 'DOC-P-005';
        //设置默认采购类型(生产备料)
        this.dictionariesDefaultData.purchaseTypeCode = 'DICT-SPT-002';
        //设置单据操作类型为生成
        this.isGenerateOperateType = 1;
        //显示操作弹框
        this.show_nodeBills = true;
      },

      /* 添加生产单据 */
      addBillsData() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(16, 1, 2, '新增')) {
          return
        }
        //将操作设置为新增
        this.operateType = 0;
        //获取需要回显的单据id(新增为-1)
        this.generateBillsId = -1;
        // 设置源单模块分类标识
        this.generateBillsSign = -1;
        //设置单据类型标签(设置为生产计划模块)
        this.billsTypeSign = 1;
        //设置单据分类类型
        this.billsClassify = this.productType;
        //设置单据分类code为生产
        this.nodeTypeCode = 'SYS-NODE-TYPE-003';
        //设置默认单据类型
        this.billsTypeDefaultCode = this.productBillCode;
        //显示操作弹框
        this.show_nodeBills = true;
      },

      /* 修改或预览生产单据 type(1:修改  2:预览)  operateOrigin操作来源(不传:本组件  1:单据公共组件)*/
      updateBillData(data, type, operateOrigin, nodeTypeCode) {
        if (type == 1) {
          //判断是否有修改权限
          if (this.commonJsExtend.isHaveThePower(16, 1, 4, '修改')) {
            return
          }
        }
        //将操作设置为对用type
        this.operateType = type;
        //获取需要回显的单据id
        this.generateBillsId = data.id;
        //设置单据类型标签(设置为生产计划模块)
        this.billsTypeSign = 1;
        //设置单据分类类型
        this.billsClassify = this.productType;
        //判断是否为预览界面的修改
        if (nodeTypeCode) {
          this.nodeTypeCode = nodeTypeCode;
        } else {
          //设置单据分类code为生产
          this.nodeTypeCode = 'SYS-NODE-TYPE-003';
        }
        //设置默认相关数据
        this.updateDefaultData = {
          nodeId: data.status_id, //节点id
          processId: data.process_id, //流程id
        }
        //设置默认单据类型
        if (!operateOrigin) {
          this.billsTypeDefaultCode = data.billingTypeInfo.code;
        }
        //显示操作弹框
        this.show_nodeBills = true;
      },

      /* 根据订单导入生产单 */
      exportBySellOrder() {
        //设置值显示销售单据
        this.showSignArr = [0];
        //设置销售单据查询条件
        this.conditionObj.is_sale_submit = '4'; //已审核单据
        this.conditionObj.is_order = 1; //订单
        this.conditionObj.order_mes_state = "0,1"; //生产状态为生产和生产中
        this.conditionObj.is_sale_pro = 1;; //设置需要货品信息
        this.conditionObj.proIsSetBom=1;//设置已设bom
        //加载单据弹框
        this.show_billsComponent = true;
        //显示单据弹框
        this.SHOW_BILLINGBOX(true);
      },

      /* 获取关联的收款单据信息 */
      getRelvanceDetail(data, sign, classify) {
        //判断是否有相应的权限
        if (this.commonJsExtend.judgeBillsSelPowerBySign(sign, classify)) {
          this.$message({
            type: 'warning',
            message: '管理员未给您开通相应查询权限,请确认!',
            duration: this.elDuration
          })
          return;
        }
        //将操作设置为修改或预览
        this.operateType = 2;
        //获取需要回显的单据id
        this.generateBillsId = data.id;
        //设置单据类型标签(设置为库存模块)
        this.billsTypeSign = sign;
        //设置默认相关数据
        this.updateDefaultData = {
          nodeId: data.statusId, //节点id
          processId: data.processId, //流程id
        }
        //设置默认单据类型
        this.billsTypeDefaultCode = data.billingTypeInfo.code;
        //设置单据分类类型
        this.billsClassify = classify;
        //设置单据分类code
        this.nodeTypeCode = this.commonJsExtend.findNodeTypeCodeBySign(sign);
        //显示操作弹框
        this.show_nodeBills = true;
      },

      /* 弹框组件 */
      judgeRelatedData(type) {
        if (type == 1) {
          this.show_supplierBox = true;
          this.SHOW_SUPPLIERBOX(true); //供应商弹框
        } else if (type == 2) {
          this.show_departmentBox = true;
          this.SHOW_DEPARTMENTBOX(true); //部门
        } else if (type == 3) {
          this.show_productBox = true;
          this.SHOW_PRODUCTBOX(true) //货品
        }
      },

      /* 获取所选择的部门数据 */
      getDepartmentData(data) {
        this.show_departmentBox = false;
        let result = this.commonJsExtend.getDeptMsg(data);
        this.offer_searchData.departName = result.nameStr; //部门
        this.offer_searchData.deptId = result.idStr; //部门id
        this.$forceUpdate()
      },

      /* 获取所选择的供应商 */
      getSupplierData(data) {
        this.show_supplierBox = false;
        this.offer_searchData.supplierName = data.fullName; //供应商
        this.offer_searchData.supplierId = data.id; //供应商id
      },

      /* 获取产品已选数据 */
      getProductData(data) {
        this.show_productBox = false;
        if (data && data.length == 0) {
          return
        }
        //判断选择产品数
        if (data && data.length > 1) {
          this.$message({
            type: 'warning',
            message: '只能选择一个产品!',
            duration: this.elDuration
          })
          return
        }
        this.offer_searchData.productName = data[0].name //货品名称
        this.offer_searchData.productId = data[0].id //货品id
      },

      /* 获取单据弹框选择的数据 */
      getBillingData(data, sign) {
        //关闭加载单据弹框
        this.show_billsComponent = false;
        //判断选择的单据模块
        if (sign == 0) {
          //判断选择的单据
          if (data.length == 0) {
            this.$message({
              type: 'warning',
              message: '未选择需要生成的单据!',
              duration: this.elDuration
            })
            return
          } else if (data.length > 1) {
            this.$message({
              type: 'warning',
              message: '只能选择一张需要生成的单据!',
              duration: this.elDuration
            })
            return
          }
          //设置生成的单据code
          let productData = {
            order_id: data[0].saleOrderMain.id, //生产生产单的订单id
          }
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#mes_tableBox", 4, '生产单生成中,请稍候...');
          //发送请求
          api.addMesMainByOrder(productData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //获取结果集
              this.generateResData = res.data;
              //显示弹框
              this.show_generateMes = true;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /*初始化列表显示字段数据 */
      initListShowFiledData() {
        //获取界面显示数组
        this.showListFiledArr = this.commonJsExtend.initListShowFiledData('mesListArr', this.mesListFileData)
          .showListFiledArr;
        //获取控制数组
        this.showListFiledData = this.commonJsExtend.initListShowFiledData('mesListArr', this.mesListFileData)
          .showListFiledData;
      },

      /* 获取当前需要显示的列表字段 */
      getCurShowFiledArr() {
        //将数据存入cookie
        this.showListFiledArr = this.commonJsExtend.getCurShowFiledArr('mesListArr', this.showListFiledData);
      },

      /* 关闭单据公共弹框 */
      closeNodeBills() {
        this.show_nodeBills = false;
      },

      /* 取消工时弹框 */
      closeHourseBox() {
        this.show_hourseBox = false;
      },

      /* 获取列表已选数据*/
      getSelectedProdData(selection, row) {
        // *****************多选*****************
        this.productSelData = selection;



        // *****************单选*****************
        //获取单选数据
        // this.productSelData = [];
        // this.productSelData.push(row);
        //设置单选
        // this.$refs.productionTableList.clearSelection();
        // this.$refs.productionTableList.toggleRowSelection(row);
        //清空单据工序数据
        this.printProcessData = [];
      },

      /* 全选列表数据 */
      getSelectedAllData(selection) {
        // ****************单选****************
        this.productSelData = selection;

        // ****************单选****************
        //直接清除,不可全选
        // this.$refs.productionTableList.clearSelection();
        //清空单据工序数据
        this.printProcessData = [];
        this.$forceUpdate();
      },

      /* 页面大小发生改变 */
      handleSizeChange(val) {
        //获取分页大小
        this.pageData.pageSize = val;
        //请求数据
        this.getListDataByCondition(this.sel_searchBtn);
      },

      /* 当前页发生改变 */
      handleCurrentChange(val) {
        //获取分页大小
        this.pageData.currentPage = val;
        //请求数据
        this.getListDataByCondition(this.sel_searchBtn);
      },

      /* 设置查看单据状态为预览(子组件调用) */
      setOperateTypeIsView() {
        this.operateType = 2;
      },

      /* 取消弹框 */
      cancleDialogOperate(){
        this.show_billsComponent = false;
      },

      /* 获取合计数据 */
      getSummaries(param) {
        const {
          columns,
          data
        } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总计';
            return;
          }
          let sumPropertys = ['plan_num', 'manufacture_num', 'success_number', 'total_amount', 'relevance_number',
            'export_num', 'print_num'
          ]; //定义需要计算的字段
          const values = data.map(item => Number(item[column.property]));
          if (sumPropertys.indexOf(column.property) != -1) {
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += '';
            } else {
              sums[index] = '';
            }
          }
        });
        return sums;
      }
    },
    components: {
      nodeBills,
      departmentTree,
      supplierList,
      productList,
      userPowerSelect,
      billExport,
      billingList,
      relvanceBillTipStrip,
      rejectBillTips,
      productionPlanMrp
    }
  }
</script>
