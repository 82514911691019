<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：生产计划单mpr界面
	开始时间：2022-03-16
	开发人员：刘巍骏
	最后修改：2022-03-16
	备注说明：如需修改请联系开发人员
-->
<template>
  <div>
    <el-dialog class="" :visible.sync="showMprBox" :fullscreen="true" :before-close="closeMprBox" id="mprAllBox">
      <div slot="title">
        <span class="headTitle">智能排产(MRP)</span>
        <el-checkbox v-model="mesProduced" @change="getSaleBillList()" v-if="billsTabIndex==0">加载不生产</el-checkbox>
        <el-checkbox v-model="mesNoProduced" @change="getSaleBillList()" v-if="billsTabIndex==0">加载已生产</el-checkbox>
        <el-checkbox v-model="purchaseProduced" @change="getMesBillList()" v-if="billsTabIndex==1">加载已请购</el-checkbox>
        <!-- 加载不请购暂时没有使用，后续需要使用可以直接开启即可 -->
        <!-- <el-checkbox v-model="purchaseNoProduced" @change="getMesBillList()" v-if="billsTabIndex==1">加载不请购</el-checkbox> -->
        <span class="mesStateTable">只看:</span>
        <el-select v-model="messtates" class="mescheck" placeholder="请选择" @change="getSaleBillList()"
          v-if="billsTabIndex==0" size="mini" clearable>
          <el-option v-for="elem in mesState" :label="elem.name" :value="elem.value">{{elem.name}}
          </el-option>
        </el-select>
        <el-button type="success" size="mini" @click="resetSaleBillList()" v-if="billsTabIndex==0" circle>复原</el-button>
        <el-select v-model="messtates" class="mescheck" placeholder="请选择" @change="getMesBillList()"
          v-if="billsTabIndex==1" size="mini" clearable>
          <el-option v-for="elem in purchaseMesState" :label="elem.name" :value="elem.value">{{elem.name}}
          </el-option>
        </el-select>
        <el-button type="success" size="mini" @click="resetMesBillList()" v-if="billsTabIndex==1" circle>复原</el-button>
      </div>
      <!-- mrp内容 -->
      <div class="mpr_all_box scollStyle" id="mprAllBox">
        <!-- 上部分 -->
        <div class="mpr_top">
          <!-- 左边单据选择 -->
          <div class="top_left">
            <!-- 表格内容 -->
            <div class="left_tab">
              <el-tabs type="border-card" v-model="billsTabIndex">
                <!-- 销售订单 -->
                <el-tab-pane label="销售订单" name="0">
                  <!-- 表格 -->
                  <div class="left_table billing_table">
                    <el-table :data="saleBillData" ref="saleTable" border height="100%" @select="getSelectedSaleData"
                      @select-all="getSelectedSaleAllData" style="width: 100%">
                      <!-- 全选 -->
                      <el-table-column type="selection" fixed width="30"></el-table-column>
                      <!-- 数据内容 -->
                      <el-table-column prop="proData" label="状态" width="80" show-overflow-tooltip>
                        <template slot-scope="scope">
                          <div  v-if="scope.row.proData.successReleaseNum!=0">
                            <el-button type="primary" size="mini">生产中</el-button>
                          </div>
                          <div v-else>
                            <el-button v-for="(elem,index) in mesState" :type="elem.class"
                              :style="{background:elem.color,border:'none'}" size="mini"
                              v-if="scope.row.billsData.mesState == elem.value">
                              {{elem.name}}
                            </el-button>
                          </div>

                        </template>
                      </el-table-column>
                      <el-table-column prop="billsData.billsNo" label="单据编号" width="180" show-overflow-tooltip>
                        <template slot-scope="scope">
                          <span class="span_pointer span_color_blue" :title="scope.row.billingTypeInfo.bills_type"
                            @click="updateBillData(scope.row,2)">{{scope.row.billsData.billsNo}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column prop="billsData.billsDate" label="单据日期" width="120" show-overflow-tooltip>
                      </el-table-column>
                      <el-table-column prop="proData.name" label="货品名称" width="150" show-overflow-tooltip>
                      </el-table-column>
                      <el-table-column prop="proData.specifications" label="规格型号" width="180" show-overflow-tooltip>
                      </el-table-column>
                      <el-table-column prop="proData.calculateName" label="单位" width="60" show-overflow-tooltip>
                      </el-table-column>
                      <el-table-column prop="proData.number" label="所需数量" width="130" show-overflow-tooltip>
                      </el-table-column>
                      <el-table-column prop="proData.successReleaseNum" label="下达数" width="130" show-overflow-tooltip>
                      </el-table-column>
                      <el-table-column prop="proData.successMes" label="生产数" width="130" show-overflow-tooltip>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-tab-pane>

                <!-- 生产计划单 -->
                <el-tab-pane label="生产计划单" name="1">
                  <!-- 表格 -->
                  <div class="left_table billing_table">
                    <el-table :data="mesBillData" ref="mesTable" border height="100%" @select="getSelectedMesData"
                      @select-all="getSelectedMesAllData" style="width: 100%">
                      <!-- 全选 -->
                      <el-table-column type="selection" fixed width="30"></el-table-column>
                      <!-- 数据内容 -->
                      <el-table-column prop="" label="状态" width="80">
                        <template slot-scope="scope">
                          <el-button v-for="(elem,index) in purchaseMesState" :type="elem.class" size="mini"
                            :style="{background:elem.color,border:'none'}"
                            v-if="scope.row.purchase_state == elem.value">
                            {{elem.name}}
                          </el-button>
                        </template>
                      </el-table-column>
                      <el-table-column prop="bills_no" label="销售单号" width="180" show-overflow-tooltip>
                        <template slot-scope="scope">
                          <span class="span_pointer span_color_blue"
                            @click="updateBillsaleData(scope.row,2)">{{(!!scope.row.relevance_bills_no)?scope.row.relevance_bills_no:"无关联"}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column prop="bills_no" label="生产单号" width="180" show-overflow-tooltip>
                        <template slot-scope="scope">
                          <span class="span_pointer span_color_blue" :title="scope.row.billingTypeInfo.bills_type"
                            @click="updateBillMesData(scope.row,2)">{{scope.row.bills_no}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column prop="bills_date" label="单据日期" width="120" show-overflow-tooltip>
                      </el-table-column>
                      <el-table-column prop="productInfo.name" label="货品名称" width="150" show-overflow-tooltip>
                      </el-table-column>
                      <el-table-column prop="productInfo.specifications" label="规格型号" width="180" show-overflow-tooltip>
                      </el-table-column>
                      <el-table-column prop="totalNumber" label="所需数量" width="130" show-overflow-tooltip>
                      </el-table-column>
                      <el-table-column prop="totalPurchaseNumber" label="已下达数量" width="130" show-overflow-tooltip>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>

            <!-- 分页信息 -->
            <div class="left_operate">
              <!-- 分页 -->
              <div class="operate_page">
                <!-- 销售分页 -->
                <div class="sale_page" v-if="billsTabIndex == 0">
                  <el-pagination @current-change="saleBillCurrentChange" :current-page="saleCurrentPage"
                    :page-size="salePageSize" layout="total, prev, pager, next" :total="saleTotal">
                  </el-pagination>
                </div>

                <!-- 生产分页 -->
                <div class="mes_page" v-if="billsTabIndex == 1">
                  <el-pagination @current-change="mesBillCurrentChange" :current-page="mesCurrentPage"
                    :page-size="mesPageSize" layout="total, prev, pager, next" :total="mesTotal">
                  </el-pagination>
                </div>
              </div>

              <!-- 操作按钮 -->
              <div class="operate_btn">
                <span class="promptly_operation" @click="billNowOperation">立即运算</span>
                <span @click="generateExcle">导出Excle</span>
                <span @click="clearList">清空列表</span>
                <span @click="getBillsData(billsTabIndex)">刷新</span>
              </div>
            </div>
          </div>

          <!-- 右边毛需求清单 -->
          <div class="top_right">
            <!-- 头部提示信息 -->
            <div class="right_head">
              毛需求清单
            </div>

            <!-- 右边内容表格 -->
            <div class="right_table billing_table">
              <el-table :data="grossRequirementData" border height="100%" :indent="18" :expand-row-keys="defaultUnfold"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}" style="width: 100%" row-key="id"
                :default-expand-all="true">
                <el-table-column prop="date" label="类型" width="120" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <!-- 成品 -->
                    <div v-if="scope.row.web_num_type && scope.row.web_num_type == -1">
                      <span>成品</span>
                    </div>
                    <!-- 物料 -->
                    <div v-else>
                      <span v-if="scope.row.children && scope.row.children.length>0">半成品</span>
                      <span v-else>物料</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="product_info_code" label="物料编号" width="160" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="product_info_name" label="物料名称" width="160" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="specifications" label="物料型号" width="160" show-overflow-tooltip></el-table-column>
                <!-- <el-table-column prop="calculate_name" label="计量单位" width="180" show-overflow-tooltip></el-table-column> -->
                <el-table-column prop="web_need_num" label="所需数量" width="100" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span>{{scope.row.web_need_num | getPurchaseChangeNumByBase(scope.row.productInfoPrice,that)}}</span>
                    <span>{{scope.row.calculate_name | getPurchaseCalculete(scope.row.productInfoPrice)}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="consume_rate" v-if="billsTabIndex==1" label="报损率" width="80" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div v-if="!!scope.row.consume_rate || scope.row.consume_rate===0 ">
                    <span>{{scope.row.consume_rate}}</span>
                    <span>‰</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="web_already_req_num" v-if="billsTabIndex==1" label="已下达数量" width="100" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span>{{scope.row.web_already_req_num | getPurchaseChangeNumByBase(scope.row.productInfoPrice,that)}}</span>
                    <span>{{scope.row.calculate_name | getPurchaseCalculete(scope.row.productInfoPrice)}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="predict_get_num" v-if="billsTabIndex==1" label="实际数量" width="100" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div v-if="!!scope.row.consume_rate">
                    <span>{{scope.row.web_need_num+scope.row.web_need_num*scope.row.consume_rate*0.001-scope.row.web_already_req_num | getPurchaseChangeNumByBase(scope.row.productInfoPrice,that)}}</span>
                    <span>{{scope.row.calculate_name | getPurchaseCalculete(scope.row.productInfoPrice)}}</span>
                    </div>
                    <div v-if="!!!scope.row.consume_rate">
                    <span>{{scope.row.web_need_num-scope.row.web_already_req_num | getPurchaseChangeNumByBase(scope.row.productInfoPrice,that)}}</span>
                    <span>{{scope.row.calculate_name | getPurchaseCalculete(scope.row.productInfoPrice)}}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="usable_number" label="可用量" width="180" show-overflow-tooltip>
                  <template slot-scope="scope">
                  <span>{{scope.row.usable_number | getPurchaseChangeNumByBase(scope.row.productInfoPrice,that)}}</span>
                    <span>{{scope.row.calculate_name | getPurchaseCalculete(scope.row.productInfoPrice)}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="warehouse_number" label="库存量" width="180" show-overflow-tooltip>
                  <template slot-scope="scope">
                  <span>{{scope.row.warehouse_number | getPurchaseChangeNumByBase(scope.row.productInfoPrice,that)}}</span>
                    <span>{{scope.row.calculate_name | getPurchaseCalculete(scope.row.productInfoPrice)}}</span>
                  </template>
                </el-table-column>
                <el-table-column v-if="demandTabIndex==0" prop="" label="生产" width="180" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-checkbox v-if="!!scope.row.children && scope.row.children.length>0" v-model="scope.row.childrenMes"></el-checkbox>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <!-- 下部分 -->
        <div class="mrp_bottom">
          <!-- 头部标题信息 -->
          <div class="bottom_head">
            生产及采购实际需求量运算
          </div>

          <!-- 内容 -->
          <div class="bottom_content">
            <el-tabs type="border-card" v-model="demandTabIndex">
              <!-- MPS生产建议列表 -->
              <el-tab-pane label="MPS生产建议列表" name="0" :disabled="billsTabIndex==1" @click="promptstatement()">
                <!-- 计算条件 -->
                <div class="content_condition">
                  <ul class="condition_ul">
                    <li class="item">
                      <el-checkbox v-model="warehouseNumDeductionM">实际库存数抵扣</el-checkbox>
                    </li>
                    <li class="item">
                      <el-checkbox v-model="onWayNumDeductionM">采购待入库数抵扣</el-checkbox>
                    </li>
                    <li class="item">
                      <el-checkbox v-model="productionNumDeductionM">生产待入库数抵扣</el-checkbox>
                    </li>
                    <li class="item">
                      <el-checkbox v-model="occupyNumDeductionM">销售待出库数抵扣</el-checkbox>
                    </li>
                    <li class="item">
                      <el-checkbox v-model="minWarehouseNumM">安全(最小量)库存</el-checkbox>
                    </li>
                    <li class="item">
                      <span class="operation" @click="getMesMrpOrMrpData">立即运算</span>
                    </li>
                  </ul>
                </div>
                <!-- 表格 -->
                <div class="left_table billing_table">
                  <el-table :data="mesProposalData" border height="100%" style="width: 100%">
                    <el-table-column prop="productInfoCeo.product_code" label="产品编号" width="140" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="productInfoCeo.name" label="产品名称" width="100" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="productInfoCeo.specifications" label="规格型号" width="150"
                      show-overflow-tooltip></el-table-column>
                    <el-table-column prop="web_needNum" label="需求数量" width="130" show-overflow-tooltip>
                      <template slot-scope="scope">
                        {{scope.row.web_needNum | getPurchaseChangeNum(scope.row,that)}}
                        {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="web_proposal" label="建议生产数量" width="130" show-overflow-tooltip>
                      <!-- 自定义表头内容 -->
                      <template slot="header" slot-scope="scope">
                        <span class="color_danger">建议生产数量</span>
                      </template>
                      <!-- 自定义值显示 -->
                      <template slot-scope="scope">
                        <span class="color_danger">
                          {{scope.row.web_proposal}}
                          {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="web_practicalNum" label="实际数量" width="130" show-overflow-tooltip>
                      <!-- 自定义表头内容 -->
                      <template slot="header" slot-scope="scope">
                        <span class="color_success">实际生产数量</span>
                      </template>
                      <!-- 自定义值显示 -->
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.web_practicalNum" size="mini" placeholder="请输入内容"></el-input>
                        {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="startDate" label="计划开工日期" width="145" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-date-picker v-model="scope.row.startDate" type="date" size="mini" placeholder="选择日期">
                        </el-date-picker>
                      </template>
                    </el-table-column>
                    <el-table-column prop="planDate" label="计划完工日期" width="145" show-overflow-tooltip>
                      <template slot-scope="scope">
                        <el-date-picker v-model="scope.row.planDate" type="date" size="mini" placeholder="选择日期">
                        </el-date-picker>
                      </template>
                    </el-table-column>
                    <el-table-column prop="totalWarehouseNumber" label="当前实际库存" width="130" show-overflow-tooltip>
                      <template slot-scope="scope">
                        {{scope.row.totalWarehouseNumber | getPurchaseChangeNumByBase(scope.row.productPriceList,that)}}
                        {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="totalOnwayNumber" label="采购待入库数" width="130" show-overflow-tooltip>
                      <template slot-scope="scope">
                        {{scope.row.totalOnwayNumber | getPurchaseChangeNumByBase(scope.row.productPriceList,that)}}
                        {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="totalProductNumber" label="生产待入库数" width="130" show-overflow-tooltip>
                      <template slot-scope="scope">
                        {{scope.row.totalProductNumber | getPurchaseChangeNumByBase(scope.row.productPriceList,that)}}
                        {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="totalOccupyNumber" label="销售待出库数" width="130" show-overflow-tooltip>
                      <template slot-scope="scope">
                        {{scope.row.totalOccupyNumber | getPurchaseChangeNumByBase(scope.row.productPriceList,that)}}
                        {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="totalMinWarning" label="安全库存" width="130" show-overflow-tooltip>
                      <template slot-scope="scope">
                        {{scope.row.totalMinWarning}}
                        {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="web_suppliername" label="委外工厂名" width="100" show-overflow-tooltip>
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.web_suppliername" @click.native="showSupplierBox(scope.$index)"
                          size="mini" placeholder="请选择供应商" clearable @change="eliminateSupplierId(scope.row)">
                        </el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" min-width="100">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.remark" size="mini" placeholder="请输入内容"></el-input>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>

              <!-- MRP采购建议 -->
              <el-tab-pane label="MRP采购建议" name="1" :disabled="billsTabIndex==0" @click="promptstatement()">
                <!-- 计算条件 -->
                <div class="content_condition">
                  <ul class="condition_ul">
                    <li class="item">
                      <el-checkbox v-model="warehouseNumDeductionP">实际库存数抵扣</el-checkbox>
                    </li>
                    <li class="item">
                      <el-checkbox v-model="onWayNumDeductionP">采购待入库数抵扣</el-checkbox>
                    </li>
                    <!-- <li class="item">
                      <el-checkbox v-model="productionNumDeductionP">生产待入库数抵扣</el-checkbox>
                    </li> -->
                    <li class="item">
                      <el-checkbox v-model="productionGetMaterialP">生产待领料数抵扣</el-checkbox>
                    </li>
                    <li class="item">
                      <el-checkbox v-model="occupyNumDeductionP">销售待出库数</el-checkbox>
                    </li>
                    <li class="item">
                      <el-checkbox v-model="minNumberDeductionP">安全(最小量)库存</el-checkbox>
                    </li>
                    <li class="item">
                      <el-checkbox v-model="minPurchaseNumP">最小采购量</el-checkbox>
                    </li>
                    <li class="item">
                      <span class="operation" @click="getPurchaseMrpOrMrpData">立即运算</span>
                    </li>
                  </ul>
                </div>
                <!-- 表格 -->
                <div class="left_table billing_table">
                  <el-table :data="purchaseProposalData" border height="100%" style="width: 100%">
                    <el-table-column prop="productInfoCeo.product_code" label="产品编号" width="160"></el-table-column>
                    <el-table-column prop="productInfoCeo.name" label="产品名称" width="140"></el-table-column>
                    <el-table-column prop="productInfoCeo.specifications" label="规格型号" width="180"
                      show-overflow-tooltip></el-table-column>
                    <el-table-column prop="web_needNum" label="需求数量" width="130">
                      <template slot-scope="scope">
                        {{scope.row.needNum | getPurchaseChangeNumByBase(scope.row.productPriceList,that)}}
                        {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="web_proposal" label="建议采购数量" width="130">
                      <!-- 自定义表头内容 -->
                      <template slot="header" slot-scope="scope">
                        <span class="color_danger">建议采购数量</span>
                      </template>
                      <!-- 自定义值显示 -->
                      <template slot-scope="scope">
                        <span class="color_danger">
                          {{scope.row.web_proposal}}
                          {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="" label="实际数量" width="130">
                      <!-- 自定义表头内容 -->
                      <template slot="header" slot-scope="scope">
                        <span class="color_success">实际采购数量</span>
                      </template>
                      <!-- 自定义值显示 -->
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.web_practicalNum" size="mini" placeholder="请输入内容"></el-input>
                        {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="" label="预计交货日期" width="145" show-overflow-tooltip>
                      <template slot-scope="scope">
                        <el-date-picker v-model="scope.row.deliveryDate" type="date" size="mini" placeholder="选择日期">
                        </el-date-picker>
                      </template>
                    </el-table-column>
                    <el-table-column prop="totalWarehouseNumber" label="当前实际库存" width="130">
                      <template slot-scope="scope">
                        {{scope.row.totalWarehouseNumber | getPurchaseChangeNumByBase(scope.row.productPriceList,that)}}
                        {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="totalOnwayNumber" label="采购待入库数" width="130" show-overflow-tooltip>
                      <template slot-scope="scope">
                        {{scope.row.totalOnwayNumber | getPurchaseChangeNumByBase(scope.row.productPriceList,that)}}
                        {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="totalAwaitTotalNum" label="生产待领料数" width="130" show-overflow-tooltip>
                      <template slot-scope="scope">
                        {{scope.row.totalAwaitTotalNum | getPurchaseChangeNumByBase(scope.row.productPriceList,that)}}
                        {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="totalOccupyNumber" label="销售待出库数" width="130" show-overflow-tooltip>
                      <template slot-scope="scope">
                        {{scope.row.totalOccupyNumber | getPurchaseChangeNumByBase(scope.row.productPriceList,that)}}
                        {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="totalMinWarning" label="安全库存" width="130" show-overflow-tooltip>
                      <template slot-scope="scope">
                        {{scope.row.totalMinWarning}}
                        {{scope.row | getPurchaseCalculete(scope.row.productPriceList)}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="productInfoCeo.order_limit_num" label="最小采购量" width="130"></el-table-column>
                    <el-table-column prop="remark" label="备注" min-width="120">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.remark" size="mini"></el-input>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>

      <!-- mrp底部操作按钮 -->
      <span slot="footer" class="dialog-footer">
        <!-- 生产建议显示 -->
        <div v-if="demandTabIndex == 0">
          <el-button type="success" @click="addMesMainByMrp">下达生产计划</el-button>
          <el-button type="primary" @click="generateExcle">生成Excle</el-button>
          <el-button @click="closeMprBox">取消</el-button>
        </div>
        <!-- 采购建议显示 -->
        <div v-if="demandTabIndex == 1">
          <el-button type="success" @click="addPurchaseMainByMpr(0)">下达采购申请单</el-button>
          <el-button type="warning" @click="addPurchaseMainByMpr(1)">按供应商下达采购申请单</el-button>
          <el-button type="primary" @click="generateExcle">生成Excle</el-button>
          <el-button @click="closeMprBox">取消</el-button>
        </div>
      </span>
    </el-dialog>

    <!-- 供应商选择弹框 -->
    <supplierList @SelectedData="getSupplierData" v-if="show_supplierComponent"></supplierList>


    <!-- 新增单据组件 -->
    <nodeBills :nodeTypeCode="nodeTypeCode" :billsClassify="billsClassify" :billsTypeSign="billsTypeSign"
      :updateDefaultData="updateDefaultData" :operateType="operateType" :generateBillsId="generateBillsId"
      :generateBillsSign="generateBillsSign" :generateBillsOriginData="generateBillsOriginData"
      :billsTypeDefaultCode="billsTypeDefaultCode" :isGenerateOperateType="isGenerateOperateType"
      :dictionariesDefaultData="dictionariesDefaultData" v-if="show_nodeBills"></nodeBills>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入接口
  import sellApi from '@/network/sell/bills.js'; //销售模块
  import purchaseApi from '@/network/stock/purchase.js'; //采购模块
  import productApi from '@/network/production/production.js'; //生产模块
  import productMrpApi from '@/network/production/production_mrp.js'; //生产mrp模块
  import nodeBills from '@/components/commComponent/billing/nodeBills'; //新建单据公共组件

  //导入组件
  import supplierList from '@/components/commComponent/list/list_supplier'; //供应商列表组件

  export default {
    name: 'productionPlanMrp',
    props: {

    },
    data() {
      return {
        //头部左边单据列表相关
        saleBillData: [], //销售订单数据
        mesBillData: [], //生产单据数据
        grossRequirementData: [], //毛需求数据
        billsTabIndex: 0, //单据tab页下标
        proSeltedIdData: [], //从单据选择的货品id数组
        billSeltedData: [], //选择的单据
        defaultUnfold: [], //默认展开的数据行

        //生产及采购需求运算
        demandTabIndex: 0, //采购及需求运算tab页
        mesProposalData: [], //生产建议列表
        purchaseProposalData: [], //采购建议列表
        curMesProPosalSupplierIndex: -1, //当前获取供应商的数据下标
        //生产建议条件
        warehouseNumDeductionM: true, //库存数抵扣
        onWayNumDeductionM: true, //待入库抵扣
        occupyNumDeductionM: true, //待出库抵扣
        productionNumDeductionM: true, //生产数抵扣
        minWarehouseNumM: false, //最小库存量抵扣

        //采购建议条件
        minNumberDeductionP: false, //安全库存抵扣
        onWayNumDeductionP: true, //待入库抵扣
        productionNumDeductionP: true, //生产待入库抵扣
        productionGetMaterialP: true, //生产待领料抵扣
        occupyNumDeductionP: true, //待出库抵扣
        warehouseNumDeductionP: true, //库存量抵扣
        minPurchaseNumP: false, //最小采购量

        //分页信息
        saleCurrentPage: 1, //销售列表当前页
        salePageSize: 20, //销售列表页大小
        saleTotal: 0, //销售列表总计
        mesCurrentPage: 1, //生产列表当前页
        mesPageSize: 20, //计划单列表数据大小
        mesTotal: 0, //生产列表总计

        //控制开关
        show_nodeBills: false, //控制显示公共单据组件弹框
        showMprBox: true, //dialog是否显示
        show_supplierComponent: false, //控制是否

        //判断是否加载已生产与未生产
        mesProduced: false,
        mesNoProduced: false,
        purchaseProduced: false,
        purchaseNoProduced: false,
        //选择框
        messtates: "",
        //传入子组件的数据
        nodeTypeCode: 'SYS-NODE-TYPE-001', //单据分类code
        operateType: 2, //操作类型(0:新增 1:修改  2:查看)
        billsTypeSign: 0, //单据分类标签(0代表销售)
        billsClassify: -1, //单据分类
        billsTypeDefaultCode: '', //默认单据类型
        isGenerateOperateType: -1, //是否为生成类型操作
        generateBillsId: -1, //需要生成其他单据的id
        generateBillsSign: -1, //源单分类标识
        generateBillsOriginData: {}, //需要生成其他单据的数据源
        dictionariesDefaultData: {}, //数据字典默认数据
        updateDefaultData: { //修改时默认流程和节点等信息
          nodeId: -1, //节点id
          processId: -1, //流程id
        },
        billType: '', //单据权期分类

        //辅助
        loading: '', //loading框
        that: this,
      }
    },
    mounted() {

    },
    watch: {
      //监听单据选择tab页切换
      billsTabIndex: {
        handler(newVal) {
          //获取列表数据
          this.getBillsData(newVal);
        },
      },
    },
    computed: {
      ...mapState({
        mesState: state => state.bill_state.mesState, //生产状态
        purchaseState: state => state.bill_state.purchaseState, //采购状态
        purchaseMesState: state => state.bill_state.purchaseMesState, //请购状态
      })
    },
    methods: {
      ...mapMutations([
        'SHOW_SUPPLIERBOX', //控制供应商弹框是否显示
      ]),
      /* 获取单据列表数据 */
      getBillsData(value) {
        //清除已选数据
        this.billSeltedData = [];

        if (value == 0) { //获取销售列表
          this.demandTabIndex = "0";
          //清除生产已选
          this.$refs.mesTable.clearSelection();

          //获取销售单据列表
          this.getSaleBillList();

        } else if (value == 1) { //获取生产列表
          this.demandTabIndex = "1";
          //清除销售已选
          this.$refs.saleTable.clearSelection();

          //没有数据时才请求

          this.getMesBillList();

        }
      },
      //清除相关供应商id
      eliminateSupplierId(data) {
        // console.log(data)
        data.web_supplierid = '';
      },
      /*重置销售单列表*/
      resetSaleBillList() {
        let saleData = {
          //必填条件
          pageIndex: this.saleCurrentPage, //当前页
          pageSize: this.salePageSize, //页面大小
          deleteFlag: 0, //删除标识(0:未作废  1:已作废)
          isOrder: 1, //是否订单
          //其它条件
          isSubmit: 4, //已审核的单据
          // depotState: "0,1", //出库状态(未出库和出库中)
          mesState: "0,1,4,5", //生产状态(未生产和生产中)
          proIsSetBom: 1, //已设置bom的
          isSaleProList: 1, //设置需要货品明细
        }
        this.mesProduced = false;
        this.mesNoProduced = false;
        this.messtates = "";
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#mprAllBox", 4, '销售单据列表重置中,请稍候...');
        //发送请求
        sellApi.findSaleOrderMainByCondition(saleData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //清空数据先
            this.saleBillData = [];
            this.saleTotal = res.data.total;
            //获取数据
            res.data.rows.forEach((item, index) => {
              if (item.salesProductInfoList.length > 0) {
                item.salesProductInfoList.forEach((itemJ, indexJ) => {
                  //判断有bom的才显示
                  if (itemJ.bomIsSet == 1) {
                    //获取总记录数
                    // this.saleTotal++;
                    //获取单个界面
                    let itemObj = {
                      proData: itemJ, //货品信息
                      billsData: item.saleOrderMain, //单据信息
                      billingTypeInfo: item.billingTypeInfo, //单据类型信息
                    }
                    //获取数据
                    this.saleBillData.push(itemObj);
                  }
                })
              }
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },
      /* 获取销售列表数据 */
      getSaleBillList() {
        let saleData = {
          //必填条件
          pageIndex: this.saleCurrentPage, //当前页
          pageSize: this.salePageSize, //页面大小
          deleteFlag: 0, //删除标识(0:未作废  1:已作废)
          isOrder: 1, //是否订单
          //其它条件
          isSubmit: 4, //已审核的单据
          // depotState: "0,1", //出库状态(未出库和出库中)
          mesState: "0,1,4,5", //生产状态(未生产和生产中)
          proIsSetBom: 1, //已设置bom的
          isSaleProList: 1, //设置需要货品明细
        }
        if (!!(this.messtates.toString())) {
          saleData.mesState = this.messtates
        }
        if (this.mesProduced && !(!!(this.messtates.toString()))) {
          saleData.mesState = "0,1,4,3"
        } else if (this.mesNoProduced && !(!!(this.messtates.toString()))) {
          saleData.mesState = "0,1,4,2"
        } else if (this.mesNoProduced && !!(this.messtates.toString())) {
          saleData.mesState = this.messtates.toString() + ",2";
        } else if (this.mesProduced && !!(this.messtates.toString())) {
          saleData.mesState = this.messtates.toString() + ",3";
        }
        if (this.mesProduced && this.mesNoProduced && !!(this.messtates.toString())) {
          saleData.mesState = this.messtates.toString() + ",2,3";
        } else if (this.mesProduced && this.mesNoProduced && !(!!(this.messtates.toString()))) {
          saleData.mesState = ""
        }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#mprAllBox", 4, '销售单据列表获取中,请稍候...');
        //发送请求
        sellApi.findSaleOrderMainByCondition(saleData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //清空数据先
            this.saleBillData = [];
            this.saleTotal = res.data.total;
            //获取数据
            res.data.rows.forEach((item, index) => {
              if (item.salesProductInfoList.length > 0) {
                item.salesProductInfoList.forEach((itemJ, indexJ) => {
                  //判断有bom的才显示
                  if (itemJ.bomIsSet == 1) {
                    //获取总记录数
                    // this.saleTotal++;
                    //获取单个界面
                    let itemObj = {
                      proData: itemJ, //货品信息
                      billsData: item.saleOrderMain, //单据信息
                      billingTypeInfo: item.billingTypeInfo, //单据类型信息
                    }
                    //获取数据
                    this.saleBillData.push(itemObj);
                  }
                })
              }
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })

      },
      /*重置生产计划单列表*/
      resetMesBillList() {
        let mesData = {
          pageIndex: this.mesCurrentPage, //当前页
          pageSize: this.mesPageSize, //页面大小
          delete_flag: 0, //删除标识(0:未作废  1:已作废)
          isMaterialTotal: 1, //设置需要物料总计信息
          isSubmit: 4, //已审核的单据
          purchase_state: "0,1,4",
          isMaterialList: 1 //需要物料表信息
        }
        this.purchaseProduced = false;
        this.purchaseNoProduced = false;
        this.messtates = "";
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#mprAllBox", 4, '生产单据列表重置中,请稍候...');
        //发送请求
        productApi.findMesMainByCondition(mesData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.mesTotal = res.data.total;
            this.mesBillData = res.data.rows;
            // console.log(this.mesBillData)
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },
      /* 获取生产计划列表数据 */
      getMesBillList() {
        let mesData = {
          pageIndex: this.mesCurrentPage, //当前页
          pageSize: this.mesPageSize, //页面大小
          delete_flag: 0, //删除标识(0:未作废  1:已作废)
          isMaterialTotal: 1, //设置需要物料总计信息
          isSubmit: 4, //已审核的单据
          purchase_state: "0,1,4",
          isMaterialList: 1 //需要物料表信息
        }
        if (!!(this.messtates.toString())) {
          mesData.purchase_state = this.messtates
        }
        if (this.purchaseProduced && !(!!(this.messtates.toString()))) {
          mesData.purchase_state = "0,1,2,4"
        } else if (this.purchaseNoProduced && !(!!(this.messtates.toString()))) {
          mesData.purchase_state = "0,1,3,4"
        } else if (this.purchaseProduced && !!(this.messtates.toString())) {
          mesData.purchase_state = this.messtates + ",2"
        } else if (this.purchaseNoProduced && !!(this.messtates.toString())) {
          mesData.purchase_state = this.messtates + ",3"
        }
        if (this.purchaseProduced && this.purchaseNoProduced && !(!!(this.messtates.toString()))) {
          mesData.purchase_state = ""
        } else if (this.purchaseProduced && this.purchaseNoProduced && !!(this.messtates.toString())) {
          this.messtates + ",2,3"
        }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#mprAllBox", 4, '生产单据列表获取中,请稍候...');
        //发送请求
        productApi.findMesMainByCondition(mesData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.mesTotal = res.data.total;
            this.mesBillData = res.data.rows;
            // console.log(this.mesBillData)
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 销售单据列表当前页改变 */
      saleBillCurrentChange(val) {
        //获取当前页
        this.saleCurrentPage = val;
        //获取数据
        this.getSaleBillList();
      },

      /* 生产单据列表当前页 */
      mesBillCurrentChange(val) {
        //获取当前页
        this.mesCurrentPage = val;
        //获取数据
        this.getMesBillList();
      },

      /* 获取选择的销售货品单据 */
      getSelectedSaleData(selection, row) {
        if (this.billSeltedData.length > 9) {
          this.$message({
            type: 'warning',
            message: "选择订单数量不能超过10条",
          })

          this.$refs.saleTable.toggleRowSelection(row, false);
          return
        }
        //获取数据
        let itemObj = {
          billType: 0, //单据类型
          billProId: row.proData.id, //单据货品id
          proId: row.proData.productId, //货品id
          proNum: row.proData.number, //货品数量
          proCalculateName: row.proData.calculateName, //计量单位
          proRemark: row.proData.remarks, //备注信息
          orderId: row.billsData.id, //订单id
          billId: row.billsData.id,
          orderName: row.billsData.billsNo, //订单编号
          orderCustomerId: row.billsData.customerId, //客户id
          orderCustomerName: row.billsData.customer_name, //客户名称
          proReleaseNum: row.proData.successReleaseNum, //下达数量
        }
        //判断是否已选
        if (JSON.stringify(this.billSeltedData).indexOf(JSON.stringify(itemObj)) == -1) {
          this.billSeltedData.push(itemObj);
        } else {
          this.billSeltedData = this.deleteArrObj(this.billSeltedData, itemObj);
        }
        //设置单选
        // this.$refs.saleTable.clearSelection();
        // this.$refs.saleTable.toggleRowSelection(row);
      },

      /* 全选销售货品单据 */
      getSelectedSaleAllData(selection) {
        //判断是选中还是非选中
        if (selection.length == 0) { //非选中
          this.saleBillData.forEach((item, index) => {
            let itemObj = {
              billType: 0, //单据类型(销售)
              billProId: item.proData.id, //单据货品id
              proId: item.proData.productId, //货品id
              proNum: item.proData.number, //货品数量
              proCalculateName: item.proData.calculateName, //计量单位
              proRemark: item.proData.remarks, //备注信息
              orderId: item.billsData.id, //订单id
              billId: item.billsData.id,
              orderName: item.billsData.billsNo, //订单编号
              orderCustomerId: item.billsData.customerId, //客户id
              orderCustomerName: item.billsData.customer_name, //客户名称
              proReleaseNum: item.proData.successReleaseNum, //下达数量
            }
            if (JSON.stringify(this.billSeltedData).indexOf(JSON.stringify(itemObj)) != -1) {
              this.billSeltedData = this.deleteArrObj(this.billSeltedData, itemObj);
            }
          })
        } else { //选中
          //获取数据
          selection.forEach((item, index) => {
            let itemObj = {
              billType: 0, //单据类型(销售)
              billProId: item.proData.id, //单据货品id
              proId: item.proData.productId, //货品id
              proNum: item.proData.number, //货品数量
              proCalculateName: item.proData.calculateName, //计量单位
              proRemark: item.proData.remarks, //备注信息
              orderId: item.billsData.id, //订单id

              orderName: item.billsData.billsNo, //订单编号
              orderCustomerId: item.billsData.customerId, //客户id
              orderCustomerName: item.billsData.customer_name, //客户名称
              proReleaseNum: item.proData.successReleaseNum, //下达数量
            }
            //判断是否已选
            if (JSON.stringify(this.billSeltedData).indexOf(JSON.stringify(itemObj)) == -1) {
              this.billSeltedData.push(itemObj);
            }
          })
        }
        // this.billSeltedData = [];
        // //直接清除,不可全选
        // this.$refs.saleTable.clearSelection();
      },
      /* 预览销售订单*/
      updateBillData(data, type, operateOrigin, nodeTypeCode) {
        // console.log(data)
        //将操作设置为对应type
        this.operateType = type;
        //设置单据类型标签(设置为销售模块)
        this.billsTypeSign = 0;
        //获取需要回显的单据id
        this.generateBillsId = data.billsData.id;
        //设置单据分类类型
        this.billsClassify = 1;
        //判断是否为预览界面的修改
        if (nodeTypeCode) {
          this.nodeTypeCode = nodeTypeCode;
        } else {
          //设置单据分类code为销售
          this.nodeTypeCode = 'SYS-NODE-TYPE-001';
        }
        //设置默认相关数据
        this.updateDefaultData = {
          nodeId: data.billsData.statusId, //节点id
          processId: data.billsData.processId, //流程id
        }
        //设置默认单据类型
        if (!operateOrigin) {
          this.billsTypeDefaultCode = data.billingTypeInfo.code;
        }
        //显示操作弹框
        this.show_nodeBills = true;
      },
      /* 预览生产计划单中关联的销售订单*/
      updateBillsaleData(data, type, operateOrigin, nodeTypeCode) {
        // console.log(data)
        //将操作设置为对应type
        this.operateType = type;
        //设置单据类型标签(设置为销售模块)
        this.billsTypeSign = 0;

        //获取需要回显的单据id
        this.generateBillsId = parseInt(data.relevance_bills);
        //设置单据分类类型
        this.billsClassify = 1;
        //判断是否为预览界面的修改
        if (nodeTypeCode) {
          this.nodeTypeCode = nodeTypeCode;
        } else {
          //设置单据分类code为销售
          this.nodeTypeCode = 'SYS-NODE-TYPE-001';
        }
        //设置默认相关数据
        this.updateDefaultData = {
          nodeId: data.originBillsMain[0].statusId, //节点id
          processId: data.originBillsMain[0].processId, //流程id
        }
        //设置默认单据类型
        if (!operateOrigin) {
          this.billsTypeDefaultCode = data.billingTypeInfo.code;
        }
        //显示操作弹框
        this.show_nodeBills = true;
      },
      /*预览生产计划单*/
      updateBillMesData(data, type, operateOrigin, nodeTypeCode) {
        // console.log(data)
        //将操作设置为对应type
        this.operateType = type;
        //设置单据类型标签(设置为生产计划单模块)
        this.billsTypeSign = 1;
        //获取需要回显的单据id
        this.generateBillsId = data.id;
        //设置单据分类类型
        this.billsClassify = 0;
        //判断是否为预览界面的修改
        if (nodeTypeCode) {
          this.nodeTypeCode = nodeTypeCode;
        } else {
          //设置单据分类code为销售
          this.nodeTypeCode = 'SYS-NODE-TYPE-003';
        }
        //设置默认相关数据
        this.updateDefaultData = {
          nodeId: data.status_id, //节点id
          processId: data.process_id, //流程id
        }
        //设置默认单据类型
        if (!operateOrigin) {
          this.billsTypeDefaultCode = data.billingTypeInfo.code;
        }
        //显示操作弹框
        this.show_nodeBills = true;
      },

      /* 获取选择的生产货品单据 */
      getSelectedMesData(selection, row) {
        //获取数据
        // console.log(row)
        let itemObj = {
          billType: 1, //单据类型(生产)
          billProId: row.id, //单据货品id
          proId: row.product_info_id, //货品id
          proNum: row.plan_num, //货品数量
          proCalculateName: row.calculate_name, //计量单位
          proRemark: "", //备注信息
          startDate:"",//计划开工日期
          planDate: "", //计划完工时间
          deliveryDate: "", //预计交货日期
          needNum: row.totalNumber, //所需数量
          orderId: row.id, //生产单id
          mesbillId: row.id,
          mesorderName: row.bills_no, //订单编号
          orderCustomerId: row.customer_id, //客户id
          orderCustomerName: row.customer_name, //客户名称
          mesMaterials: row.mesMaterial, //物料明细
        }
        //判断是否已选
        if (JSON.stringify(this.billSeltedData).indexOf(JSON.stringify(itemObj)) == -1) {
          this.billSeltedData.push(itemObj);
        } else {
          this.billSeltedData = this.deleteArrObj(this.billSeltedData, itemObj);
        }
        // //设置单选
        // this.$refs.mesTable.clearSelection();
        // this.$refs.mesTable.toggleRowSelection(row);
      },

      /* 全选生产货品单据 */
      getSelectedMesAllData(selection) {
        //判断是选中还是非选中
        if (selection.length == 0) { //非选中
          this.mesBillData.forEach((item, index) => {
            //获取数据
            let itemObj = {
              billType: 1, //单据类型(生产)
              billProId: item.id, //单据货品id
              proId: item.product_info_id, //货品id
              proNum: item.plan_num, //货品数量
              proCalculateName: item.calculate_name, //计量单位
              proRemark: "", //备注信息
              startDate:"",//计划开工日期
              planDate: "", //计划完工时间
              deliveryDate: "", //预计交货日期
              needNum: item.totalNumber, //所需数量
              orderId: item.id, //生产单id
              mesbillId: row.id,
              mesorderName: row.bills_no, //订单编号
              orderCustomerId: item.customer_id, //客户id
              orderCustomerName: item.customer_name, //客户名称
              mesMaterials: item.mesMaterial, //物料明细
            }
            //判断是否已选
            if (JSON.stringify(this.billSeltedData).indexOf(JSON.stringify(itemObj)) == -1) {
              this.billSeltedData.push(itemObj);
            }
          })
        }
        // this.billSeltedData = [];
        // //直接清除,不可全选
        // this.$refs.mesTable.clearSelection();
      },

      /* 单据上的立刻运算(获取毛需求列表清单)*/
      billNowOperation() {
        //定义货品id数组
        let proIdArr = [];
        //获取货品id数组
        this.billSeltedData.forEach((item, index) => {
          //去重
          if (!proIdArr.includes(item.proId)) {
            proIdArr.push(item.proId);
          }
        })

        //判断是否选择数据
        if (proIdArr.length == 0) {
          this.$message({
            type: 'warning',
            message: "请先选择需要运算的数据!",
            duration: this.elDuration
          })
          return
        }

        //定义请求数据
        let data = {
          product_ids: proIdArr.join(","), //货品ids
        }
        data.demandTabIndex=this.demandTabIndex
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#mprAllBox", 4, '毛需求列表获取中,请稍候...');
        //发送请求
        productApi.findDefaultBomVersionByProductIds(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            console.log(res.data)
            // console.log(res.data)
            //毛需求清单数据
            this.grossRequirementData = [];
            //获取物料数据
            res.data.forEach((item, index) => {
              //获取成品数据
              let itemObj = {
                id: 0 - index,
                web_num_type: -1, //前端显示的类型(-1表示成品)
                product_info_id: item.productInfo.id, //货品id
                product_info_code: item.productInfo.product_code, //物料编号
                product_info_name: item.productInfo.name, //物料名称
                specifications: item.productInfo.specifications, //物料规格
                warehouse_number:item.productInfo.warehouse_number,//实际库存
                usable_number:item.productInfo.usable_number,//可用库存
              }

              //处理bom物料数据
              let bomMaterialNoTree = this.commonJsExtend.toNoTree(item.mesMainBomList, []);

              //定义已经处理过的货品id数组
              let disposeSelProArr = [];

              //循环获取需求总量信息
              this.billSeltedData.forEach((itemK, indexK) => {
                //根据货品id处理总需求量
                if (itemK.proId == item.productInfo.id) {
                  //获取成品计量单位
                  itemObj.calculate_name = itemK.proCalculateName;
                  //获取成品所需数量
                  itemObj.need_num = itemK.proNum;

                  //判断是否已经处理过
                  if (disposeSelProArr.includes(itemK.proId)) {

                    //获取货品需求总量信息
                    itemObj.web_need_num = itemObj.web_need_num + itemK.proNum;
                    // console.log(itemObj.web_need_num)
                    bomMaterialNoTree.forEach((itemJ, indexJ) => {
                      //获取需求总数
                      itemJ.web_need_num = itemJ.web_need_num + (itemJ.need_num * itemK.proNum);
                      if(this.billsTabIndex==1){
                      //获取下达数量
                      if(itemK.mesMaterials.length>indexJ&&!!itemK.mesMaterials[indexJ].already_req_num){
                        itemJ.web_already_req_num=itemJ.web_already_req_num+(itemK.mesMaterials[indexJ].already_req_num)
                      }
                      }
                      //获取成品id
                      itemJ.web_finishedpro_id = itemK.proId;
                    })
                    // console.log(itemObj.web_need_num)
                  } else {

                    disposeSelProArr.push(itemK.proId);
                    //获取货品需求总量信息
                    itemObj.web_need_num = itemK.proNum;
                    bomMaterialNoTree.forEach((itemJ, indexJ) => {
                      //获取需求总数
                      itemJ.web_need_num = itemJ.need_num * itemK.proNum;
                      //获取下达数量(生产计划单使用)
                      if(this.billsTabIndex==1){
                        if(itemK.mesMaterials.length>indexJ && !!itemK.mesMaterials[indexJ].already_req_num){
                           itemJ.web_already_req_num=itemK.mesMaterials[indexJ].already_req_num
                        }else{
                          itemJ.web_already_req_num=0
                        }
                      }



                      //获取成品id
                      itemJ.web_finishedpro_id = itemK.proId;
                    })

                  }
                }
              })

              //处理层级显示关系
              bomMaterialNoTree.forEach((item, index) => {
                if (item.bomType == 0) { //顶级
                  item.parentid = -1;
                } else if (item.bomType == 1) { //子级
                  if (item.mes_bom_parent_id = -1) {
                    item.parentid = item.mes_main_bom_id;
                  } else {
                    item.parentid = item.mes_bom_parent_id;
                  }
                }
              })

              //转换为树形结构
              bomMaterialNoTree = this.commonJsExtend.toTree(bomMaterialNoTree, 'parentid');
              //获取子级数据
              itemObj.children = bomMaterialNoTree;

              //获取毛需求清单
              this.grossRequirementData.push(itemObj);

            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },
      /* 关闭单据公共弹框 */
      closeNodeBills() {
        this.show_nodeBills = false;
      },
      /* 获取生产建议列表数据 */
      getMesMrpOrMrpData() {
        //定义货品id数组
        let proIdArr = [];
        //订单id数组
        let orderIds=[];

        //获取货品id数组
        this.billSeltedData.forEach((item, index) => {
          if(!proIdArr.includes(item.proId)){
            proIdArr.push(item.proId);
          }
          if(!orderIds.includes(item.orderId)){
            orderIds.push(item.orderId);
          }

        })
        //查看是否有需要生产含有子级的半成品
        this.grossRequirementData.forEach((item,index)=>{
          if(!!item.children && item.children.length>0){
             item.children.forEach((itemI,indexI)=>{
             //当勾选生产按钮时
            if(!!itemI.childrenMes && itemI.childrenMes){
                 if(!proIdArr.includes(itemI.product_info_id)){
                    proIdArr.push(itemI.product_info_id)
                    orderIds.push(this.billSeltedData[index].orderId)
                 }    
            }
            })
          }
          
        })
        //定义请求数据
        let data = {
          productids: proIdArr.join(","), //货品ids
          orderIds:orderIds.join(","),//订单id
        }
         data.demandTabIndex=this.demandTabIndex
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#mprAllBox", 4, '生产建议列表获取中,请稍候...');
        //发送请求
        productMrpApi.findMesMrpProList(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取总库存相关信息
            this.mesProposalData = [];
            //货品id数组
            let productIdList = [];

            //获取生产建议数据
            this.billSeltedData.forEach((item, index) => {
              if (productIdList.includes(item.proId)) {
                this.mesProposalData.forEach((itemK, indexK) => {
                  if (itemK.productInfoCeo.id == item.proId) {
                    //获取生产建议相关数量数据
                    itemK.web_needNum = itemK.web_needNum + item.proNum; //所需数量
                    itemK.web_practicalNum = 0; //实际生产数量
                    itemK.proReleaseNum = itemK.proReleaseNum + item.proReleaseNum //下达数量
                    itemK.orderName = itemK.orderName + "," + item.orderName //订单编号
                    itemK.billId = itemK.orderId + "," + item.orderId
                  }
                })
              } else {
                let itemObj = {};
                res.data.forEach((itemJ, indexJ) => {
                  if (itemJ.productInfoCeo.id == item.proId) {
                    itemObj = itemJ;
                                    //获取生产建议相关数量数据
                itemObj.startDate=item.startDate//计划开工日期
                itemObj.planDate = item.planDate //计划完工时间
                itemObj.billProId = item.billProId, //单据货品id
                itemObj.proReleaseNum = item.proReleaseNum //下达数量
                itemObj.web_needNum = item.proNum; //所需数量
                itemObj.web_practicalNum = 0; //实际生产数量
                itemObj.orderId = item.orderId; //获取订单id
                itemObj.billId = item.orderId,
                itemObj.orderName = item.orderName, //订单编号
                itemObj.proId = item.proId; //获取货品id
                itemObj.calculateName = item.proCalculateName; //获取货品计量单位名称
                itemObj.remark = item.proRemark; //获取货品备注信息
                itemObj.customerId = item.orderCustomerId; //获取货品客户id信息
                itemObj.customerName = item.orderCustomerName; //获取货品客户名称信息

                //获取生产建议列表(未处理生产建议数量)
                this.mesProposalData.push(itemObj);
                //获取已经合并的货品id
                productIdList.push(item.proId);
                  }
                  else{
                    if(!!this.grossRequirementData[index].children && this.grossRequirementData[index].children.length>0){
                      this.grossRequirementData[index].children.forEach((itemY,indexY)=>{
                         if(!!itemY.childrenMes && itemY.childrenMes && itemJ.productInfoCeo.id==itemY.product_info_id){
                           itemObj = itemJ
                           //获取生产建议相关数量数据
                itemObj.startDate=item.startDate//计划开工日期
                itemObj.planDate = item.planDate //计划完工时间
                itemObj.billProId = item.billProId, //单据货品id
                itemObj.proReleaseNum = item.proReleaseNum //下达数量
                itemObj.web_needNum = itemY.web_need_num; //所需数量
                itemObj.web_practicalNum = 0; //实际生产数量
                itemObj.orderId = item.orderId; //获取订单id
                itemObj.billId = item.orderId,
                itemObj.orderName = item.orderName, //订单编号
                itemObj.proId = item.proId; //获取货品id
                itemObj.calculateName = item.proCalculateName; //获取货品计量单位名称
                itemObj.remark = item.proRemark; //获取货品备注信息
                itemObj.customerId = item.orderCustomerId; //获取货品客户id信息
                itemObj.customerName = item.orderCustomerName; //获取货品客户名称信息

                //获取生产建议列表(未处理生产建议数量)
                this.mesProposalData.push(itemObj);
                //获取已经合并的货品id
                productIdList.push(item.proId);
                         }
                    })
                    }
                    
                  }
                })

              }
            })

            //获取生产建议列表(已处理生产建议数量)
            this.mesProposalData.forEach((item, index) => {
              //获取建议数据
              this.getMesProposalNum(item);
              //根据建议生产数量获取默认生产数量
              if (item.web_proposal > 0) {
                item.web_practicalNum = item.web_proposal;
              }
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取采购建议列表数据 */
      getPurchaseMrpOrMrpData() {
        //判断是否已经生成毛需求数量信息
        if (this.grossRequirementData.length == 0) {
          this.$message({
            type: 'warning',
            message: "请先生成毛需求列表数据!",
            duration: this.elDuration
          })
          return
        }
        //判断是否已经生成生产建议列表数据
        // if (this.mesProposalData.length == 0) {
        //   this.$message({
        //     type: 'warning',
        //     message: "请先生成生产建议列表数据!",
        //     duration: this.elDuration
        //   })
        //   return
        // }

        //获取非树形结构
        let grossRequirementDataNoTree = this.commonJsExtend.toNoTree(this.grossRequirementData, []);
        //过滤掉货品
        grossRequirementDataNoTree = grossRequirementDataNoTree.filter(item => !item.web_num_type);
        //定义货品id数组
        let proIdArr = [];

        //定义计划单id
        let mesId=[];

        this.billSeltedData.forEach((item,index)=>{
          mesId.push(item.mesbillId)
        })
        //获取货品id数组
        grossRequirementDataNoTree.forEach((item, index) => {

          if (!proIdArr.includes(item.product_info_id)) {
            proIdArr.push(item.product_info_id);
          }
        })

        //定义请求数据
        let data = {
          productids: proIdArr.join(","), //货品ids
          mesIds:mesId.join(","),//计划单id
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#mprAllBox", 4, '生产建议列表获取中,请稍候...');
        //发送请求
        productMrpApi.findMesMrpProList(data).then(res => {
          if (res.code == 200) {
            // console.log(res.data)
            //获取总库存相关信息
            this.purchaseProposalData = [];
            //货品id数组
            let productIdList = [];
            //获取采购建议数据
            grossRequirementDataNoTree.forEach((item, index) => {
              if (productIdList.includes(item.product_info_id)) {
                this.purchaseProposalData.forEach((itemK, indexK) => {
                  // console.log(this.purchaseProposalData)
                  if (itemK.productInfoCeo.id == item.product_info_id) {

                    //获取采购建议相关数量数据
                    this.mesProposalData.forEach((itemZ, indexZ) => {
                      if (item.web_finishedpro_id == itemZ.productInfoCeo.id) {
                        //获取报损量
                        let comsume_num = Math.ceil(item.need_num * item.consume_rate / 1000);
                        itemK.web_needNum = itemK.web_needNum + ((item.need_num + comsume_num) * itemZ
                          .web_practicalNum); //所需数量(物料所需数量+物料报损量)*成品所需数量
                      }
                    })
                    itemK.web_practicalNum = 0; //实际采购数量
                    //如果该货品报损率不为0
                    if(!!item.consume_rate){
                      itemK.needNum=itemK.needNum+item.web_need_num+(item.web_need_num*item.consume_rate*0.001-item.web_already_req_num)
                    }else{
                        itemK.needNum=itemK.needNum+item.web_need_num-item.web_already_req_num
                    }

                  }
                })
              } else {
                let itemObj = {};
                res.data.forEach((itemJ, indexJ) => {
                  if (itemJ.productInfoCeo.id == item.product_info_id) {
                    itemObj = itemJ;
                  }
                })
                //获取采购建议相关数量数据
                this.mesProposalData.forEach((itemZ, indexZ) => {
                  if (item.web_finishedpro_id == itemZ.productInfoCeo.id) {
                    //获取报损量
                    let comsume_num = Math.ceil(item.need_num * item.consume_rate / 1000);
                    itemObj.web_needNum = (item.need_num + comsume_num) * itemZ
                      .web_practicalNum; //所需数量(物料所需数量+物料报损量)*成品所需数量
                  }
                })
                itemObj.deliveryDate = item.deliveryDate //预计交货日期
                itemObj.web_practicalNum = 0; //实际采购数量
                //如果该货品报损率不为0
                if(!!item.consume_rate){
                  itemObj.needNum =item.web_need_num+(item.web_need_num*item.consume_rate*0.001-item.web_already_req_num)
                }else{
                   itemObj.needNum =item.web_need_num-item.web_already_req_num;
                }

                //设置委外供应商相关
                itemObj.web_suppliername = itemObj.productInfoCeo.supplierName; //委外供应商名称
                itemObj.web_supplierid = itemObj.productInfoCeo.supplier_id; //委外供应商id
                itemObj.remark = item.productInfo.desciption; //获取货品备注信息
                console.log(item)
                console.log(itemObj)

                //获取客户信息
                // itemObj.customerId = item.orderCustomerId;//获取货品客户id
                // itemObj.customerName = item.orderCustomerName;//获取货品客户名称


                //获取采购建议列表
                this.purchaseProposalData.push(itemObj);
                // console.log(this.purchaseProposalData)
                //获取已经合并的货品id
                productIdList.push(item.product_info_id);
              }
            })
            this.purchaseProposalData.forEach((item, index) => {
              //获取建议数据
              this.getPurchaseProposalNum(item);

              //根据采购建议,获取默认采购数量
              if (item.web_proposal > 0) {
                item.web_practicalNum = item.web_proposal;
              }
            })
            //关闭loading框
            this.loading.close();
          } else {
            //关闭loading框
            this.loading.close();
            //显示提示信息
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取货品生产建议数量 */
      getMesProposalNum(data) {
        //设置默认建议生产量为需求数量
        let result = data.web_needNum - data.proReleaseNum;
        //转换成基础计量单位
        result = this.commonJsExtend.getBaseChangeNumber(result, data.productPriceList, data.calculateName);
        //判断是否有待出库抵扣条件
        if (!this.occupyNumDeductionM) { //没有
          result = data.web_needNum - data.proReleaseNum;

          //转换成基础计量单位
          result = this.commonJsExtend.getBaseChangeNumber(result, data.productPriceList, data.calculateName);
        }



        // 判断是否库存数抵扣(实际库存)
        if (this.warehouseNumDeductionM) {
          result = result - data.totalWarehouseNumber;
        }
        //判断是否待入库抵扣(在途量)
        if (this.onWayNumDeductionM) {
          result = result - data.totalOnwayNumber;
        }
        //判断是否待出库数抵扣(占用量)
        if (this.occupyNumDeductionM) {
          result = result + data.totalOccupyNumber;
        }
        //判断是否生产量抵扣(生产量)
        if (this.productionNumDeductionM) {
          result = result - data.totalProductNumber;
        }

        //判断安全库存
        if (this.minWarehouseNumM) {
          //判断需要生产的数量是否小于最小库存量
          if (result < data.totalMinWarning) { //是

            // 判断建议数量是否大于0
            if (result >= 0) {
              // result = data.totalMinWarning - result;
              result = data.totalMinWarning;
            } else {
              result = data.totalMinWarning + result;
            }
          }
        }
        // console.log(result)
        //转换回采购计量单位
        result = this.commonJsExtend.getPurchaseNumByBase(result, data.productPriceList);
        //  console.log(result)
        //获取生产建议数量
        data.web_proposal = result < 0 ? 0 : result;

        // console.log(data.web_proposal)
      },
      /*给予相应提示方法 */
      promptstatement() {
        if (this.demandTabIndex = "1") {
          this.$message({
            message: '请先选择销售订单才可查看生产建议',
            type: 'warning'
          });
        } else if (this.demandTabIndex = "0") {
          this.$message({
            message: '请先选择生产计划单才可查看采购建议',
            type: 'warning'
          });
        }
      },
      /* 获取货品采购建议数量 */
      getPurchaseProposalNum(data) {
        //设置默认建议生产量为需求数量
        let result = 0;
        let releasequantity = 0; //物料下单数量总量
        this.billSeltedData.forEach((item, index) => {
          item.mesMaterials.forEach((itemk, indexk) => {
            if (data.productInfoCeo.id == itemk.product_info_id) {
              releasequantity = releasequantity + itemk.already_req_num;
            }
          })
        })
        //转换为采购计量单位
          // result = data.needNum - releasequantity;
          result = data.needNum ;
          //转换成基础计量单位
          result = this.commonJsExtend.getBaseChangeNumber(result, data.productPriceList, data.calculateName);
        //判断是否有待出库抵扣条件
        if (!this.occupyNumDeductionP) { //没有
          //转换为采购计量单位
          // result = data.needNum - releasequantity;
          result = data.needNum;


          //转换成基础计量单位
          result = this.commonJsExtend.getBaseChangeNumber(result, data.productPriceList, data.calculateName);
        }
        //判断库存数抵扣
        if (this.warehouseNumDeductionP) {
          result = result - data.totalWarehouseNumber;
        }
        //判断是否待入库抵扣(在途量)
        if (this.onWayNumDeductionP) {
          result = result - data.totalOnwayNumber;
        }
        //判断是否待出库数抵扣(占用量)
        if (this.occupyNumDeductionP) {
          result = result + data.totalOccupyNumber;
        }
        // //判断生产待入库数
        // if (this.productionNumDeductionP) {
        //   result = result - data.totalProductNumber;
        // }
        //生产待领料数抵扣
        if (this.productionGetMaterialP) {
          result = result + data.totalAwaitTotalNum;
        }
        // 判断安全库存抵扣(最小库存量)
        if (this.minNumberDeductionP) {
          //判断需要生产的数量是否小于最小库存量
          if (result < data.totalMinWarning) { //是
            // 判断建议数量是否大于0
            if (result >= 0) {
              // result = data.totalMinWarning - result;
               result = data.totalMinWarning ;
            } else {
              result = data.totalMinWarning + result;
            }
          }
        }

        // 判断是否最小采购量
        if (this.minPurchaseNumP) {
          if (result < data.productInfoCeo.order_limit_num) {
            result = data.productInfoCeo.order_limit_num;
          }
        }

        //转换回采购计量单位
        result = this.commonJsExtend.getPurchaseNumByBase(result, data.productPriceList);

        //获取采购建议数量
        data.web_proposal = result < 0 ? 0 : result;
      },

      /* 根据生产建议下达生产计划单 */
      addMesMainByMrp() {
        //定义需求数据
        let proIdArr = []; //货品id数组
        let proNumArr = []; //货品需求数量数组
        let saleIdArr = []; //销售订单id数组
        let saleProIdArr = []; //销售货品id数组
        let remarkArr = []; //货品备注数组
        let startDateArr=[];//计划完工日期
        let planDateArr = []; //预计生产完成日期
        let customerIdArr = []; //订单客户id数组
        let customerNameArr = []; //订单客户名称数组
        let supplierIdArr = []; //供应商id数组
        let supplierNameArr = []; //供应商名称数组
        let RelevanceBillsArr = []; //单据号id
        let RelevanceBillsNameArr = []; //单据编号
        //判断是否已生成数据
        if (this.mesProposalData.length == 0) {
          this.$message({
            type: 'warning',
            message: "请先生成生产建议列表数据!",
            duration: this.elDuration
          })
          return
        }
        //获取数据
        this.mesProposalData.forEach((item, index) => {
          // console.log(item);
          //实际生产数量大于0的才下单
          if (item.web_practicalNum > 0) {
            proIdArr.push(item.productInfoCeo.id); //生产的货品id
            proNumArr.push(item.web_practicalNum); //实际生产数量
            saleIdArr.push(item.orderId); //当前货品所属订单id
            saleProIdArr.push(item.billProId); //当前货品所属订单货品id
            remarkArr.push(item.remark); //当前货品设置的备注信息
            if(!!item.startDate){
              startDateArr.push(this.commonJsExtend.getDateTime(item.startDate, 0))//当前计划开工日期
        }else{
          startDateArr.push("")
        }
            if (!!item.planDate) {
              planDateArr.push(this.commonJsExtend.getDateTime(item.planDate, 0)); //当前计划时间
            } else {
              planDateArr.push(""); //当前计划时间
            }
            customerIdArr.push(item.customerId); //当前货品单据的客户id信息
            customerNameArr.push(item.customerName); //当前货品单据的客户名称信息
            if (!!item.web_supplierid) {
              supplierIdArr.push(item.web_supplierid); //当前供应商id信息
              supplierNameArr.push(item.web_suppliername); //当前供应商名称信息
            } else {
              supplierIdArr.push(""); //当前供应商id信息
              supplierNameArr.push(""); //当前供应商名称信息
            }
            RelevanceBillsArr.push(item.billId); //单据id
            RelevanceBillsNameArr.push(item.orderName); //单据编号
          }
        })

        //判断是否有需要下单单据的货品
        if (proIdArr.length == 0) {
          this.$message({
            type: 'warning',
            message: "请先输入需要下单生产单的货品数量!",
            duration: this.elDuration
          })
          return
        }

        //定义添加数据
        let mrpData = {
          proIds: proIdArr.join(","), //货品id
          proNums: proNumArr.join(","), //货品数量
          saleOrderIds: saleIdArr.join(","), //订单id
          saleProIds: saleProIdArr.join(","), //订单货品id
          proRemarks: remarkArr.join(","), // 货品备注
          startDate:startDateArr.join(","),//计划开工日期
          planDate: planDateArr.join(","), //计划完成时间
          saleCustomerIds: customerIdArr.join(","), //货品订单客户id
          saleCustomerNames: customerNameArr.join(","), //货品订单客户名称
          supplierIds: supplierIdArr.join(","), //供应商id信息
          supplierNames: supplierNameArr.join(","), //供应商名称信息
          relevanceBills: RelevanceBillsArr.join(","), //单据id
          relevanceBillsNames: RelevanceBillsNameArr.join(",") //单据编号
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#mprAllBox", 4, '生产单据添加中,请稍候...');
        //发送请求
        productApi.addMesMainByMrp(mrpData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: "生产计划单下达成功!",
              duration: this.elDuration
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 根据mpr计算结果下达采购申请单 */
      addPurchaseMainByMpr(type) {
        //定义需求数据
        let proIdArr = []; //货品id数组
        let proNumArr = []; //货品需求数量数组
        let proRemarkArr = []; //货品备注数组
        let customerIdArr = []; //订单客户id数组
        let customerNameArr = []; //订单客户名称数组
        let deliveryDateArr = []; //预计交货日期
        let RelevanceBillsArr = []; //单据号id
        let RelevanceBillsNameArr = []; //单据编号
        let materialsArr = []; //关联物料id
        this.billSeltedData.forEach((item, index) => {
          RelevanceBillsArr.push(item.mesbillId)
          RelevanceBillsNameArr.push(item.mesorderName)
          if (this.billSeltedData.length == 1) {
            item.mesMaterials.forEach((itemk, indexk) => {
              materialsArr.push(itemk.id)
            })
          }
        })

        //判断是否已生成数据
        if (this.purchaseProposalData.length == 0) {
          this.$message({
            type: 'warning',
            message: "请先生成采购建议列表数据!",
            duration: this.elDuration
          })
          return
        }
        //获取数据
        this.purchaseProposalData.forEach((item, index) => {
          //有实际数量的才下达
          if (item.web_practicalNum > 0) {
            proIdArr.push(item.productInfoCeo.id); //采购的货品id
            proNumArr.push(item.web_practicalNum); //采购生产数量
            proRemarkArr.push(item.remark); //当前货品设置的备注信息
            customerIdArr.push(item.customerId); //当前货品单据的客户id信息
            customerNameArr.push(item.customerName); //当前货品单据的客户名称信息
            if (!!item.deliveryDate) {
              deliveryDateArr.push(this.commonJsExtend.getDateTime(item.deliveryDate, 0))
            } else {
              deliveryDateArr.push("")
            }

          }
        })

        //判断是否有需要下单单据的货品
        if (proIdArr.length == 0) {
          this.$message({
            type: 'warning',
            message: "请先输入需要下单采购单的货品数量!",
            duration: this.elDuration
          })
          return
        }

        //定义添加数据
        let mrpData = {
          type: type, //操作方式(0:统一生成  1:按供应商生成)
          proIds: proIdArr.join(","), //货品id
          proNums: proNumArr.join(","), //货品数量
          proRemark: proRemarkArr.join(","), //货品备注
          saleCustomerIds: customerIdArr.join(","), //货品订单客户id
          saleCustomerNames: customerNameArr.join(","), //货品订单客户名称
          deliveryDates: deliveryDateArr.join(","), //预计交货日期
          relevanceBills: RelevanceBillsArr.join(","), //单据id
          relevanceBillsNames: RelevanceBillsNameArr.join(","), //单据编号
          materials: materialsArr.join(",")
        }
        // console.log(mrpData);

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#mprAllBox", 4, '采购单据添加中,请稍候...');
        //发送请求
        purchaseApi.addPurchaseApplyForByMrp(mrpData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: "采购单下达成功!",
              duration: this.elDuration
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 生成excle */
      generateExcle() {
        this.$message({
          type: 'warning',
          message: "该功能尚未开放!",
          duration: this.elDuration
        })
      },

      /* 清空列表数据 */
      clearList() {
        //清空毛需求数据
        this.grossRequirementData = [];
        //清空单据选择的货品id数组
        this.proSeltedIdData = [];
        //清空选择的单据
        // this.billSeltedData = [];
        //清空默认展开行的数据
        this.defaultUnfold = [];
        //清空生产建议列表
        this.mesProposalData = [];
        //清空采购建议列表
        this.purchaseProposalData = [];

        this.$message({
          type: 'success',
          message: "数据已清空!",
          duration: this.elDuration
        })
      },

      /* 显示供应商弹框 */
      showSupplierBox(index) {
        // console.log(index);
        this.curMesProPosalSupplierIndex = index;
        //加载弹框
        this.show_supplierComponent = true;
        //显示弹框
        this.SHOW_SUPPLIERBOX(true);
      },

      /* 获取供应商选择弹框 */
      getSupplierData(data) {
        //关闭弹框
        this.show_supplierComponent = false;
        //获取数据
        this.$set(this.mesProposalData[this.curMesProPosalSupplierIndex], "web_suppliername", data.fullName);
        this.$set(this.mesProposalData[this.curMesProPosalSupplierIndex], "web_supplierid", data.id);
        this.$forceUpdate();
      },

      /* 删除数组中的某个元素 */
      deleteArrObj(data, obj) {
        let result = JSON.parse(JSON.stringify(data));
        result.forEach((item, index) => {
          if (JSON.stringify(item).indexOf(JSON.stringify(obj)) != -1) {
            result.splice(index, 1);
          }
        })
        return result;
      },

      /* 关闭mrp弹框 */
      closeMprBox() {
        this.$parent.closeMesMrpBox();
      },
    },
    components: {
      nodeBills,
      supplierList
    },
    filters: {
      /* 获取物料总计数量 */
      getMaterialTotalNum(value, proId, data) {
        // console.log(value);
        // console.log(proId);
        // console.log(data);
        let result = value;
        data.forEach((item, index) => {
          if (item.proId == proId) {
            result = result * item.proNum;
          }
        })
        return result;
      },
      /* 指定单位换算成采购计量单位 */
      getPurchaseChangeNum(number, row, that) {
        //定义返回结果
        let resnum = number;

        // 获取多计量数据
        let productPriceList = row.productPriceList;
        //转换为采购计量单位
        resnum = that.commonJsExtend.getPurchaseChangeNum(resnum, productPriceList, row.calculateName);

        return resnum;
      },
      /* 根据基础计量单位换算成采购计量单位(库存相关) */
      getPurchaseChangeNumByBase(number, productPriceList, that) {
        //定义返回结果
        let resnum = number;

        if (!!productPriceList) {
          //调用公共方法获取数量
          resnum = that.commonJsExtend.getPurchaseNumByBase(resnum, productPriceList);
        }

        return resnum;
      },
      // 获取采购计量单位
      getPurchaseCalculete(defaultCalculate, productPriceList) {
        //定义返回值
        let resCalculate = defaultCalculate;

        if (!!productPriceList) {
          //获取基础计量单位
          let baseCalculateArr = productPriceList.filter(item => item.is_base == 1);
          if (baseCalculateArr.length > 0) {
            resCalculate = baseCalculateArr[0].calculateName;
          }

          //获取采购计量单位
          let purchaseCalculateArr = productPriceList.filter(item => item.is_purchase_default == 1);
          if (purchaseCalculateArr.length > 0) {
            resCalculate = purchaseCalculateArr[0].calculateName;
          }
        }

        return resCalculate;
      },
    }
  }
</script>

<!-- element-ui相关样式 -->
<style>
  /* tab页内容 */
  .mpr_all_box .el-tabs--border-card>.el-tabs__content {
    padding: 5px;
  }
</style>
<style lang="less" scoped="scoped">
  // 自定义头部标题样式
  .headTitle {
    font-size: 14px;
    padding: 0 10px 0 0;
    font-weight: bold;
  }

  // mpr总框内容
  .mpr_all_box {
    height: 80vh;
    overflow: auto;

    // border: 1px solid red;
    //单元格显示小手样式
    .span_pointer {
      cursor: pointer;
    }

    //蓝色文字
    .span_color_blue {
      color: @theme;
    }

    .span_color_blue:hover {
      color: #0037ff;
    }

    //上部分
    .mpr_top {
      min-height: 50vh;
      display: flex;
      justify-content: space-between;
      border: 1px solid @borderColor;

      //左边内容
      .top_left {
        width: 49.7%;
        // border: 1px solid black;

        // tab页面框
        .left_tab {
          height: calc(100% - 45px);
          // border: 1px solid black;

          //表格内容
          .left_table {
            height: 35vh;
          }
        }

        // 操作框
        .left_operate {
          height: 45px;
          display: flex;
          padding-left: 10px;
          border: 1px solid @borderColor;

          //分页框
          .operate_page {
            width: calc(100% - 250px);
            display: flex;
            align-items: center;
            // border: 1px solid black;
          }

          //操作按钮
          .operate_btn {
            width: 315px;
            display: flex;
            align-items: center;
            // border: 1px solid black;

            .promptly_operation {
              color: @theme;
            }

            .promptly_operation:hover {
              color: blue;
            }

            span {
              padding: 3px 10px;
              display: block;
              cursor: pointer;
            }

            span:hover {
              color: @theme;
            }
          }
        }
      }

      //右边内容
      .top_right {
        width: 49.7%;
        border: 1px solid @borderColor;

        // 头部内容
        .right_head {
          height: 35px;
          font-size: 16px;
          font-weight: bold;
          display: flex;
          color: black;
          justify-content: center;
          align-items: center;
          // border: 1px solid yellow;
        }

        // 表格内容
        .right_table {
          height: 43vh;
          padding: 5px;
          // border: 1px solid black;
        }
      }
    }

    //下部分
    .mrp_bottom {
      min-height: 50vh;
      margin-top: 10px;
      border: 1px solid @borderColor;

      //头部标题
      .bottom_head {
        height: 40px;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        color: black;
        justify-content: center;
        align-items: center;
      }

      //内容
      .bottom_content {

        //条件
        .content_condition {
          padding: 10px;
          border: 1px solid @borderColor;

          // 条件按钮
          .condition_ul {
            display: flex;

            //单个li
            .item {
              padding: 0 10px;

              //运算按钮
              .operation {
                cursor: pointer;
                color: @theme;
              }

              .operation:hover {
                color: blue;
              }
            }
          }
        }

        //表格内容
        .left_table {
          height: 50vh;
          margin-top: 10px;
        }
      }
    }
  }

  .mesStateTable {
    padding: 0 5px 0 20px;
    font-weight: bold;
  }

  .mescheck {
    margin-right: 10px;
    margin-left: 10px;
    width: 100px;
  }
</style>
