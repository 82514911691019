// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：系统.生产相关请求
// 开始时间：2021-05-06
// 开发人员：万攀
// 最后修改：2021-05-06
// 备注说明：如需修改请联系开发人员

import {
  request
} from "../request.js";
import qs from 'qs';

export default{
  findMesMrpProList(data){//根据多个货品id获取Mrp需要的数据列表集合
    return request({
      method: 'POST',
      url: '/mes/findMesMrpProList',
      data,
    })
  },
}
